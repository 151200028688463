<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.resources') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm" 
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow class="my-2">
            <label class="col-sm-11 col-lg-1 col-xl-1 text-right mt-2">{{$t('label.filter')}}</label>
            <CCol sm="11" lg="2" xl="2" class="center-field px-2">
                <CInput
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required 
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="5" xl="5" class="center-field"></CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";

    //Data
    function data() {
        return {
            textSearch:'',
            VisitId: '',
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            rows: [],
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            loadingOverlay:false,
            disabledFilterByPreference: false,
            nulo:'', 
            pageSize:'',
            search: '',
        }
    }

    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };

        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    function mounted() {
       // this.AllgridApi = this.gridOptions.api;
       // this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    async function getExcelArray2(rowData, titulo, excel, companyName) {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(titulo);
        const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

        let y = 0;
        let letra = 14;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;

        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera = false;

        //if (isUndefined(un)) un = 'TON';
        if (excel) {
            let tituloExcel = "";
            if(this.itinerarySelected !== '' || this.itinerarySelected !== null){
                tituloExcel = this.itinerarySelected.VesselName+" "+this.itinerarySelected.Imo+", "+this.itinerarySelected.CountryName+", "+this.$t('label.berth')+': '+this.itinerarySelected.BerthName+", "+this.itinerarySelected.LabelArrival+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Arrival)+", "+this.itinerarySelected.LabelDeparture+": "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Departure)+" - "+this.$t('label.voyage')+": "+this.itinerarySelected.Voyage;
                this.dataBuque.CountryName = this.itinerarySelected.CountryName;
                this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
            }

            if (y < 10) letra = 10;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path:this.user.LogoRoute.replace('Public/','', null, 'i')
            });
            
            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(companyName){
                worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2")   
            }else if(companyName === undefined){ 
                worksheet.mergeCells("C1:" + this.numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + this.numeroLetra(y) + "2");
            }
            
            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = companyName ? 60 : 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.branch.BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            if(companyName){
            }else if(companyName === undefined){     
                const row2 = worksheet.getRow(2);    
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = tituloExcel;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:" + this.numeroLetra(y) +"3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = titulo;
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //columnas
            let division = Math.floor(y / 3);
            let rango1 = this.numeroLetra(division);
            let rango11 = this.numeroLetra(division+1);
            let rango2 = this.numeroLetra(division*2);
            let rango21 = this.numeroLetra((division * 2) +1);
            let rango3 = this.numeroLetra(y);

            
            const hoy = new Date();

            if(companyName){
                worksheet.mergeCells("A4:" + rango1 +"4");
                const customCellCompany = worksheet.getCell("A4");
                worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                customCellCompany.height = 50;
                customCellCompany.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };

                customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                customCellCompany.value = this.$t('label.company')+": "+companyName;
                customCellCompany.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                    left: { style: 'thin' },
                }
            }else if(companyName === undefined){
                worksheet.mergeCells("A4:" + rango1 +"4"); //fecha de impresion
                worksheet.mergeCells(rango11 + "4:" + rango3 + "4");  //usuario
            }

            const customCell6 = companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4");
            customCell6.height = 50;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell6.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //usuario
            const customCell7 = companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4");
            customCell7.height = 50;
            customCell7.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell7.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }

            //linea
            worksheet.mergeCells("A5:Q5");
            const customCell8 = worksheet.getCell("A5");
            customCell8.height = 50;
            customCell8.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        var headerRow = worksheet.addRow();
        headerRow.font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };
    /* headerRow.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
        } */
        headerRow.height = 30;

        let i = 0;
        columnsDefs.map(function (data) {
            let valor = bandera ? data.headerName.length : data.length;
            let long = 10;
            if ((valor >= 5) && (valor <= 12)) {
                long = 20;
            } else if ((valor >= 11) && (valor <= 18)) {
                long = 25;
            } else if ((valor >= 19) && (valor <= 26)) {
                long = 30;
            } else if (valor >= 27) {
                long = 40;
            }

            //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

            worksheet.getColumn(i + 1).width = long;
            //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
            //}        
            i++;
        })

        rowData.map(async function (data) {
            if (data.Cont20FtDeck=="DECK") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:F7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }            
                cellA4.value = data.Cont20FtDeck;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("G7:J7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont20FtHold;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("K7:N7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont20FtTotal;

                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }

            } else if (data.Cont20Full == "20F") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                worksheet.mergeCells("C6:C7");
                //Grupo 1
                worksheet.mergeCells("D7:E7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:G7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("H7:I7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("J7:K7");
                cellA4 = worksheet.getCell("J7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 5
                worksheet.mergeCells("L7:M7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 6
                worksheet.mergeCells("N7:O7");
                cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
                //Grupo 1
                worksheet.mergeCells("N7:P7");
                let cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.CtaFunctionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("Q7:R7");
                cellA4 = worksheet.getCell("R7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.ComAddressIdentifier;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("S7:V7");
                cellA4 = worksheet.getCell("S7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.HanDescriptionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("W7:Y7");
                cellA4 = worksheet.getCell("W7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.TsrServicePriorityCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.section=="totalLoad") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:E7");
                let cellA4 = worksheet.getCell("C7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    },
                }            
                cellA4.value = "DECK";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:H7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = "HOLD";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            }else {
                let dataRow = worksheet.addRow();
                let indice = 0;
                
                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);
                    cell.value = data[data2];
                    //if (cell.value==)
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    indice++;
                })
            }
        })

        let fileName = documento;
        let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
        today = today.replaceAll("/", "");
        let time = new Date();
        time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
        //return false;
        if (excel) {
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                })
        } else {
            workbook.csv.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                })
        }
    }
    function getResourceList(){
        this.loadingOverlay = true;
        this.$http.ejecutar('GET', 'VisitRoleDetailReportAll-list', { VisitId:this.Visit,VisitResourceId:'' }, '')
        .then(response => {
            this.rows = [...response.data.data];
            this.loadingOverlay = false;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            this.loadingOverlay = false;
        });
    }
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }
    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }
    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }
    function refreshComponent() {
        this.textSearch="";
        this.pageSize=""; 
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.getResourceList();
    }
    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.formatedItems.length !== 0) {
            await this.getPdf(rowData,this.$t('label.resources'));
            }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }
    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.formatedItems.length !== 0) {
            let itemsArray = [];

            await rowData.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.IdNumber')] = item.NumberId;
                objeto[this.$t('label.name')] = item.Name;
                objeto[this.$t('label.lastName')] = item.LastName;
                objeto[this.$t('label.payrollType')] = item.PayRollName;
                objeto[this.$t('label.stowageRole')] = item.StowageRoleName;
                objeto[this.$t('label.company')] = item.ClientName;
                objeto[this.$t('label.date')] = item.TransaRegDate;
                objeto[this.$t('label.user')] = item.TransaLogin;
                itemsArray.push(objeto);
            });

            await this.getExcelArray2(itemsArray,this.$t('label.resources'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }
    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            this.rows.map(function(item, index){
                computedItems.push({
                    id: item.Nro,
                    NumberId: item.NumberId ? item.NumberId : '',
                    Name: item.Name ? item.Name : '',
                    LastName: item.LastName ? item.LastName : '',
                    PayRollName: item.PayRollName ? item.PayRollName : '',
                    StowageRoleName: item.StowageRoleName ? item.StowageRoleName : '',
                    ClientName: item.ClientName ? item.ClientName : '',
                    TransaRegDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
                    TransaLogin: item.TransaLogin ? item.TransaLogin : '',             
                })
            })
        }
        return computedItems.filter(item => {
            return item
        })
    }
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                suppressMovable: true,
                headerName: "#",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                lockVisible: true,
                lockPosition: true,
                maxWidth: 70,
                //pinned: 'left',
            },
            {
                field: "NumberId",
                headerName: this.$t('label.IdNumber'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "Name",
                headerName: this.$t('label.name'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "LastName",
                headerName: this.$t('label.lastName'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "PayRollName",
                headerName: this.$t('label.payrollType'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "StowageRoleName",
                headerName: this.$t('label.stowageRole'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "ClientName",
                headerName: this.$t('label.company'), 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "TransaLogin",
                headerName: this.$t('label.user'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
            {
                field: "TransaRegDate",
                headerName: this.$t('label.date'),
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
            },
        ]
        return columnDefs;
    }
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    export default {
        name:'index-recurso',
        data,
        mounted,
        beforeMount,
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            onGridReady,
            onQuickFilterChanged,
            onPageSizeChanged,
            onBtnPdf,
            onBtnExport,
            getResourceList,
            refreshComponent,
            getExcelArray2
        },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            },
        },
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                Visit: state => state.visitas.VisitId,
                tabIndex: state => state.visitas.tabIndex,
                user: (state) => state.auth.user,
                itinerarySelected: state => state.visitas.itinerarySelected,
                dropItemAgenda: state => state.visitas.dropItemAgenda,
                dropItemReport: state => state.visitas.dropItemReport,
                branch: state => state.auth.branch,
            })
        },
        watch:{
            textSearch,          
            dropItemReport: function (newValue) {
                if ((newValue==4) && (this.Visit!="")) {
                    this.VisitId = this.Visit;
                    this.refreshComponent();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        },
    }
</script>