<template>
    <div class="container-fluid mb-4" style="margin-top:20px;">
        <modalEmpleado :modal="showEmpleado" @cerrarModal="showEmpleado=false" @child-refresh="refrescarTabla=true" />
        <CRow>
            <CCol sm="4">
                <CSelect
                    :label="$t('label.payrollType')"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                    @change="changeValue($event.target.value)"
                    :options="payrollOptions"
                    v-model.trim="payrollTypeId"
                    :value.sync="payrollTypeId"
                    :invalid-feedback="$t('label.required')"
                />
            </CCol>
            <CCol sm="4">
                <CSelect
                    :label="$t('label.company')"
                    :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                    @change="changeCompanyOption($event.target.value)"
                    :options="companyOptions"
                    v-model.trim="companyId"
                    :value.sync="companyId"
                    :invalid-feedback="$t('label.required')"
                />
            </CCol>
            <CCol sm="4 text-right">
                <CButton size="sm" class="mr-1" color="wipe" @click="limpiarDatos"
                    v-c-tooltip="{
                        content:  $t('label.clearFilters'),
                        placement: 'top-end'
                    }"
                >
                    <CIcon name="cil-brush-alt"/>
                </CButton>
                <CButton
                    color="excel"
                    size="sm"
                    style="margin-left:5px;"
                    @click="generarXLS(true)"
                    v-c-tooltip="{
                        content: $t('label.exportToExcel'),
                        placement: 'top-end'
                    }"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XLSX
                </CButton>
            </CCol>
            <CCol sm="12" v-if="collapseItems">
                <dataTableExtended
                    class="align-center-row-datatable"
                    size="lg"
                    :fields="fields"
                    :items="dateFilteredItems"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableTextHelpers.tableFilterText"
                    :items-per-page-select="tableTextHelpers.itemsPerPageText"
                    :noItemsView="tableTextHelpers.noItemsViewText"
                    hover
                    sorter
                >
                    <template #Status="{item}">
                        <td class="text-center">
                            <CBadge v-if="item.status" color="success">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                            <CBadge v-if="!item.status" color="danger">
                                {{ $t('label.'+item.Status) }}
                            </CBadge>
                        </td>
                    </template>
                    <template #Detalle="{ item }">
                        <td class="center-cell">
                            <CButton color="edit" style="margin:5px;" size="sm" v-c-tooltip="{content: $t('label.edit'),placement: 'top'}" @click="showEmpleado=item">
                                <CIcon name="pencil" />
                            </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
    </div>
</template>


<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import { DateFormater } from '@/_helpers/funciones';
    import modalEmpleado from './modal-edit-empleado';
    import Reportes from '@/_mixins/reportes';
    import {saveAs} from "file-saver";
    import Excel from "exceljs/dist/es5/exceljs.browser";
    //data
    function data() {
        return {
            refrescarTabla: false,
            listItems : [],
            payrollOptions:[],
            payrollTypeId: '',
            companyId: '',
            collapseItems: true,
            companyOptions:[],
            showEmpleado: false,
        }
    }
    //methods
    async function getExcelArray2(rowData, titulo, excel, companyName, vesselInfo = true, nombreLibro) {
        const workbook = new Excel.Workbook();
        let nombreOpcional = nombreLibro ? nombreLibro : titulo;
        const worksheet = workbook.addWorksheet(nombreOpcional);
        const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

        let y = 0;
        let letra = 12;
        let columnsDefs = [];
        let keyColumns = [];
        let bandera = true;

        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera = false;

        //if (isUndefined(un)) un = 'TON';
        if (excel) {
            if(vesselInfo){
                if(companyName === undefined){
                    const Index = this.myDataItinirary.map(function(e) {
                        return e.ItineraryId; 
                    }).indexOf(this.ItineraryId);
                    
                    if(Index > -1){      
                        this.dataBuque.DatePlanning = this.myDataItinirary[Index].VesselName+" "+this.myDataItinirary[Index].Imo+", "+this.myDataItinirary[Index].CountryName+", ETA: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta)+", ETD: "+DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd)+" - "+this.$t('label.voyage')+": "+this.myDataItinirary[Index].Voyage;
                        this.dataBuque.CountryName = this.myDataItinirary[Index].CountryName;
                    } else {
                        let itinerarySelected = this.itinerarySelected;
                        this.dataBuque.DatePlanning = `${itinerarySelected.VesselName}, - ${this.$t('label.voyage')}: ${itinerarySelected.Voyage}, ${itinerarySelected.LabelArrival} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Arrival)} - ${itinerarySelected.LabelDeparture} ${DateFormater.formatDateTimeWithSlash(itinerarySelected.Departure)}`;
                        this.dataBuque.CountryName = this.itinerarySelected.CountryName;
                        this.dataBuque.VesselFlagRoute = this.itinerarySelected.VesselFlagRoute;
                    }
                }
            }
            
            if (y < 10) letra = (y < 2 ? 12 : 8);
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(vesselInfo){
                if(companyName){
                    worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2");
                }else if(companyName === undefined){ 
                    worksheet.mergeCells("C1:" + this.numeroLetra(y) + "1");
                    worksheet.mergeCells("C2:" + this.numeroLetra(y) + "2");
                }
            }else{
                if(this.numeroLetra(y) === "B"){
                    worksheet.mergeCells("C1:F2");
                }else{  
                    worksheet.mergeCells("C1:" + this.numeroLetra(y) + "2");
                }
            }
            const customCellCompanyBranch = worksheet.getCell("C1");
            const row1 = worksheet.getRow(1);
            if(vesselInfo){
                row1.height = companyName ? 60 : 30;
            }else{
                row1.height = 60;
            }
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.branchSelected.BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            if(vesselInfo){
                if(companyName){
                }else if(companyName === undefined){     
                    const row2 = worksheet.getRow(2);    
                    row2.height = 30;
                    const customCell = worksheet.getCell("C2");
                    customCell.font = {
                        name: "Calibri",
                        family: 4,
                        size: letra,
                        underline: false,
                        bold: true
                    };
                    customCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: 'f2f2f2'
                        }
                    }
                    customCell.border = {
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    }
                    customCell.value = this.dataBuque.DatePlanning;
                    customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                }
            }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:" + ( this.numeroLetra(y) === "B" ? "F" : this.numeroLetra(y) ) +"3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = titulo;
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //COLUMNA 2
            let division = Math.floor((y === 2 ? 4 : y ) / 3);
            let rango1 = this.numeroLetra(division);
            let rango11 = this.numeroLetra(division+1);
            let rango2 = this.numeroLetra(division*2);
            let rango21 = this.numeroLetra((division * 2) +1);
            let rango3 = this.numeroLetra((y === 2 ? 4 : y ));
            
            const hoy = new Date();

            if(vesselInfo){
                if(companyName){
                    worksheet.mergeCells("A4:" + rango1 +"4");
                    const customCellCompany = worksheet.getCell("A4");
                    worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                    worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                    customCellCompany.height = 50;
                    customCellCompany.font = {
                        name: "Calibri",
                        family: 4,
                        size: 11,
                        underline: false,
                        bold: true
                    };

                    customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                    customCellCompany.value = this.$t('label.company')+": "+companyName;
                    customCellCompany.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                        left: { style: 'thin' },
                    }
                }else if(companyName === undefined){
                    worksheet.mergeCells("E4:" + rango2 +"4"); //fecha de impresion
                    worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
                }
            }else{
                worksheet.mergeCells("E4:" + rango2 +"4"); //fecha de impresion
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
            }

            //TIPO NOMINA
            worksheet.mergeCells("A4:B4");
            const customCellAux1 = worksheet.getCell("A4");
            customCellAux1.height = 50;
            customCellAux1.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };

            customCellAux1.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCellAux1.value = this.$t('label.payrollType')+": "+this.textPayrol;
            customCellAux1.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            worksheet.mergeCells("C4:D4");
            const customCellAux2 = worksheet.getCell("C4");
            customCellAux2.height = 50;
            customCellAux2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };

            customCellAux2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCellAux2.value = this.$t('label.company')+": "+this.textCompany;
            customCellAux2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //Impreso
            const customCell6 = vesselInfo ? (companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("E4")) : worksheet.getCell("E4");
            customCell6.height = 50;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };

            customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell6.value = this.$t('label.printed')+": "+hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell6.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //usuario
            const customCell7 = vesselInfo ? (companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4")) : worksheet.getCell(rango21 + "4");
            customCell7.height = 50;
            customCell7.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };

            customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell7.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }
                        
            //linea
            worksheet.mergeCells("A5:" + rango3 +"5");
            const customCell8 = worksheet.getCell("A5");
            customCell8.height = 50;
            customCell8.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }

        var headerRow = worksheet.addRow();
        headerRow.font = {
            name: "Calibri",
            family: 4,
            size: 11,
            underline: false,
            bold: true
        };
    /* headerRow.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
        } */
        headerRow.height = 30;

        let i = 0;
        columnsDefs.map(function (data) {
            let valor = bandera ? data.headerName.length : data.length;
            let long = 10;
            if ((valor >= 5) && (valor <= 12)) {
                long = 20;
            } else if ((valor >= 11) && (valor <= 18)) {
                long = 25;
            } else if ((valor >= 19) && (valor <= 26)) {
                long = 30;
            } else if (valor >= 27) {
                long = 40;
            }

            //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

            worksheet.getColumn(i + 1).width = long;
            //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            
            cell.value = bandera ? data.headerName : data;
            cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center' }
            //}        
            i++;
        });

        worksheet.getColumn(2).width = 28;

        rowData.map(async function (data) {
            if (data.Cont20FtDeck=="DECK") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:F7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }            
                cellA4.value = data.Cont20FtDeck;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("G7:J7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont20FtHold;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("K7:N7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont20FtTotal;

                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }

            } else if (data.Cont20Full == "20F") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                worksheet.mergeCells("C6:C7");
                //Grupo 1
                worksheet.mergeCells("D7:E7");
                let cellA4 = worksheet.getCell("D7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:G7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.Cont20Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("H7:I7");
                cellA4 = worksheet.getCell("H7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("J7:K7");
                cellA4 = worksheet.getCell("J7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.Cont40Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 5
                worksheet.mergeCells("L7:M7");
                cellA4 = worksheet.getCell("L7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Full;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 6
                worksheet.mergeCells("N7:O7");
                cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.Cont45Empty;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {            
                //Grupo 1
                worksheet.mergeCells("N7:P7");
                let cellA4 = worksheet.getCell("N7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.CtaFunctionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("Q7:R7");
                cellA4 = worksheet.getCell("R7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    }
                }
                cellA4.value = data.ComAddressIdentifier;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 3
                worksheet.mergeCells("S7:V7");
                cellA4 = worksheet.getCell("S7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = data.HanDescriptionCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 4
                worksheet.mergeCells("W7:Y7");
                cellA4 = worksheet.getCell("W7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '5d6164'
                    }
                }
                cellA4.value = data.TsrServicePriorityCode;
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            } else if (data.section=="totalLoad") {
                //Grupo 0
                worksheet.mergeCells("A6:A7");
                worksheet.mergeCells("B6:B7");
                //Grupo 1
                worksheet.mergeCells("C7:E7");
                let cellA4 = worksheet.getCell("C7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = { 
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '1a3760'
                    },
                }            
                cellA4.value = "DECK";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                //Grupo 2
                worksheet.mergeCells("F7:H7");
                cellA4 = worksheet.getCell("F7");
                cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                cellA4.font = {
                    name: "Calibri",
                    family: 4,
                    underline: false,
                    bold: true,
                    color: { argb: 'ffffff' },
                };
                cellA4.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'ec631b'
                    }
                }
                cellA4.value = "HOLD";
                cellA4.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
            }else {
                let dataRow = worksheet.addRow();
                let indice = 0;
                
                keyColumns.map(function (data2) {
                    let cell = dataRow.getCell(indice + 1);
                    cell.value = data[data2];
                    //if (cell.value==)
                    cell.border = {
                        top: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                    indice++;
                })
            }
        })

        let fileName = documento;
        let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
        today = today.replaceAll("/", "");
        let time = new Date();
        time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
        //return false;
        if (excel) {
            workbook.xlsx.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                }).catch(err => {
                    console.log(err);
                })
        } else {
            workbook.csv.writeBuffer()
                .then(buffer => {
                    saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                    this.$notify({
                        group: 'container',
                        text: this.$t('label.reportSuccessfully'),
                        type: "success"
                    });
                })
        }
    }
    async function generarXLS(valor){ 
        if(this.dateFilteredItems.length !== 0){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            let itemsArray = [];
            await this.dateFilteredItems.map(async(item,index) => {
                let objeto = {};
                objeto['#'] = index+1;
                objeto[this.$t('label.IdNumber')] = item.NumberId;
                objeto[this.$t('label.name')] = item.Name;
                objeto[this.$t('label.lastName')] = item.LastName;
                objeto[this.$t('label.payrollType')] = item.PayRollName;
                objeto[this.$t('label.stowageRole')] = item.StowageRoleName;
                objeto[this.$t('label.company')] = item.ClientName;
                objeto[this.$t('label.user')] = item.UltimoUsuario;
                objeto[this.$t('label.date')] = item.FormatedDate;
                objeto[this.$t('label.status')] = item.Status;
                itemsArray.push(objeto);
            });

            await this.getExcelArray2(itemsArray, this.$t('label.stowageGans'), valor);
            this.$store.state.visitas.apiStateForm = ENUM.INIT;
        }
    }
    async function limpiarDatos(){
        this.payrollTypeId= '';
        this.companyId= '';
        await this.changeCompanyOption('');
    }
    async function changeValue(item){
        if(item !== null && item !== ''){
            this.$store.state.visitas.apiStateForm = ENUM.LOADING;
            let listado2 = [];
            this.companyOptions = [];
            this.$http.get("VisitResource-by-PayRollId", { PayRollId: this.payrollTypeId,VisitId:this.VisitId })
            .then(async(response) => {
                listado2 = response.data.data;
                this.companyOptions = listado2.map(listado => Object.assign({}, this.companyOptions, {
                    value: listado.VisitResourceId,
                    label: listado.ClientName
                }));
                this.companyOptions.unshift({
                    value: "",
                    label: this.$t('label.select')
                });
                this.companyId = '';
                await this.changeCompanyOption('');
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }).catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            });
        }else{
            this.companyId = '';
            this.listItems = [];
            this.companyOptions = [];
            this.companyOptions.unshift({
                value: "",
                label: this.$t('label.select')
            });

            await this.changeCompanyOption('');
        }
    }
    async function changeCompanyOption(item){
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        await this.$http.get("VisitRoleDetailAll-list", { VisitId: this.VisitId,VisitResourceId: item,PayRollId: this.payrollTypeId }).then(async (responseAll) => { 
            if(responseAll.status === 200){
                this.listItems = [...responseAll.data.data];
                this.$store.state.visitas.apiStateForm = ENUM.INIT;
            }else{
                this.$store.state.visitas.apiStateForm = ENUM.ERROR;
            }
        },error => {
            this.$store.commit('visitas/messageMutation', error);
            this.$store.state.visitas.apiStateForm = ENUM.ERROR;
        });
    }
    async function getData(){
        this.$store.state.visitas.apiStateForm = ENUM.LOADING;
        await this.$http.get("PayRoll-list")
        .then(async(response) => {
            let listado = [...response.data.data].filter(ob => ob.Status==="ACTIVO");
            listado = listado.filter(ob => ob.FgSupplier === true);
            this.payrollOptions = listado.map(listado => Object.assign({}, this.payrollOptions, {
                value: listado.PayRollId,
                label: listado.PayRollName,
                FgSupplier: listado.FgSupplier
            }));
            this.payrollTypeId = '';
            let listado2 = [];
            this.payrollOptions.unshift({
                value: "",
                label: this.$t('label.select')
            });
            this.companyOptions = [];
            this.companyOptions.unshift({
                value: "",
                label: this.$t('label.select')
            });

            await this.changeCompanyOption('');
            
            this.$store.state.visitas.apiStateForm = ENUM.INIT;
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
    }
    //computeds
    function dateFilteredItems(){
        let items = this.listItems;
        items.sort(function (a, b) {
            if (a.FgActVisitRole < b.FgActVisitRole) return 1;
            else if (a.FgActVisitRole > b.FgActVisitRole) return -1;
            else {
                return a.NumberId - b.NumberId;
            }
        })
        if(this.payrollTypeId !== ''){
            let payrollTypeId = this.payrollTypeId;
            items = items.filter((ob) => ob.PayRollId == payrollTypeId);
        }
        return items.map((item,index) => Object.assign({}, item, {
            RowNumber: index+1,
            UltimoUsuario: item.TransaLogin,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            status: item.FgActVisitRole,
            _classes: ( item.FgActVisitRole ) ? '' : 'table-danger'
        }));;
    }
    function fields(){
        return [
            { 
                key: 'RowNumber', 
                label: '#',
                _style: 'width:5%; text-align:center;', filter: false, _classes: 'text-center',sorter: true
            },
            { key: 'NumberId', label: this.$t('label.IdNumber'),sorter: true,_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { key: 'Name', label: this.$t('label.name'),sorter: true},
            { key: 'LastName', label: this.$t('label.lastName'),sorter: true},
            { key: 'PayRollName', label: this.$t('label.payrollType'),sorter: true},
            { key: 'StowageRoleName', label:this.$t('label.stowageRole'),sorter: true},
            { key: 'ClientName', label:this.$t('label.company'),sorter: true},
            { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
            { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
            { key: 'Status',label: this.$t('label.status'),_classes:'center-cell text-center', _style:'width:10%;',sorter: true },
            { 
                key: 'Detalle',
                _style:'width:50px;text-align: center;',
                _classes:"center-cell",
                label: '', 
                sorter: false, 
                filter: false
            }
        ]
    }
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function textPayrol(){
        if(this.payrollTypeId === '' || this.payrollTypeId === null ){
            return 'N/A';
        }else{
            let text = 'N/A';
            this.payrollOptions.map(async (e) => {
                if(this.payrollTypeId === e.value){
                    text = e.label;
                }
            })
            return text;
        }
    }
    function textCompany(){
        if(this.companyId === '' || this.companyId === null ){
            return 'N/A';
        }else{
            let text = 'N/A';
            this.companyOptions.map(async (e) => {
                if(this.companyId === e.value){
                    text = e.label;
                }
            })
            return text;
        }
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    export default {
        name:'asignacion-recurso',
        props:['value'],
        mixins:[
            Reportes
        ],
        data,
        methods:{
            getData,
            changeValue,
            changeCompanyOption,
            limpiarDatos,
            generarXLS,
            getExcelArray2
        },
        components: {
            modalEmpleado,
        },
        computed:{
            textCompany,
            textPayrol,
            dateFilteredItems,
            fields,
            apiStateLoading,
            tableTextHelpers,
            apiStateLoading,
            ...mapState({
                user: state => state.auth.user,
                branchSelected: state => state.auth.branch,
                apiState: (state) => state.visitas.apiState,
                apiStateForm: (state) => state.visitas.apiStateForm,
                EdiFileId: (state) => state.visitas.EdiFileId,
                EdiFileInfo: (state) => state.visitas.EdiFileInfo,
                VisitId: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,
                itinerarySelected: state => state.visitas.itinerarySelected
            })
        },
        watch:{
            refrescarTabla: function () {
                if (this.refrescarTabla) {
                    let companyId = this.companyId ? this.companyId : '';
                    this.changeCompanyOption(companyId);
                    this.refrescarTabla = false;
                }
            },
            dropItemReport: function (newValue,OldValue){
                if(newValue === 21){
                    this.getData();
                    //this.limpiarDatos();
                }else if(OldValue === 21){
                    this.payrollTypeId = '';
                    this.companyId = ''; 
                }
            },
            value(newValue){
                if(newValue === 0 && this.dropItemReport === 21){
                    let companyId = this.companyId ? this.companyId : '';
                    this.changeCompanyOption(companyId);
                }
            }
        }
    }
</script>