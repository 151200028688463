<template>
  <div class="planificacion-body" v-if="showLayout">
    <matching-modal
      :modal.sync="matchingModal"
      :matchInfo="matching"
      :modulo="modulo"
      :visitEdiFileId="!visitInfo ? '' : visitInfo.EdiFileId"
      @generated="handleMatching"
    />
    <div class="wrapper d-flex align-items-stretch">
      <nav id="detailSidebar">
        <div class="pb-4 pt-1 sidebar-inner-space">
          <ul class="list-unstyled components mb-5 mx-4">
            <li class="text-center align-middle mb-4">
              <h3>BAY VIEW</h3>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click.stop="printPdf"
              >
                <CIcon name="cil-print"/>
                <span class="ml-1">{{$t('label.print')}}</span>
              </CButton>
            </li>
            <li>
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click.stop="refreshBaysList"
                v-if="modulo == 'visit'"
              >
                <CIcon name="cil-reload"/>
                <span class="ml-1">{{$t('label.refresh')}}</span>
              </CButton>
            </li>
          </ul>
          <ul class="list-unstyled components">
            <li>
              <div class="filter-collapse" @click.stop="toggleMultiple">
                <span class="ml-1">{{$t('label.multipleFilter')}}</span>
                <CIcon
                  name="cil-chevron-bottom"
                  :class="{
                    'ml-3': true,
                    'arrow-icon': true,
                    'rotate': multiple,
                  }"
                />
              </div>
              <CCollapse :show="multiple">
                <div class="mx-4 my-2">
                  <CButton
                    block
                    size="sm"
                    color="secondary"
                    class="d-flex align-items-center justify-content-center"
                    @click.stop="toggleMatching"
                  >
                    <CIcon name="cil-filter" />
                    <span class="ml-1">{{$t('label.matching')}}</span>
                  </CButton>
                </div>
              </CCollapse>
            </li>
            <li>
              <div class="filter-collapse" @click.stop="toggleNormales">
                <span class="ml-1">{{$t('label.generalFilter')}}</span>
                <CIcon
                  name="cil-chevron-bottom"
                  :class="{
                    'ml-3': true,
                    'arrow-icon': true,
                    'rotate': normales,
                  }"
                />
              </div>
              <CCollapse :show="normales">
                <div class="mx-4 my-2">
                  <div class="selects-container-general mb-2">
                    <span>{{$t('label.filterBy')}}</span>
                    <div class="position-relative">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.selectFilter')"
                        class="no-margin-select ml-2"
                        :options="filtros"
                        :disabled="isFiltrosEmpty"
                        :value.sync="filtro"
                      />
                      <loading v-show="isLoadingFiltros" element="select"/>
                    </div>
                  </div>
                  <div class="selects-container-general mb-2">
                    <span>{{$t('label.port')}}</span>
                    <div class="position-relative">
                      <CSelect
                        size="sm"
                        :placeholder="$t('label.select')"
                        class="no-margin-select ml-2"
                        :options="puertosOptions"
                        :disabled="isPuertosEmpty"
                        :value.sync="puerto"
                      />
                      <loading v-show="isLoadingPuertos" element="select"/>
                    </div>
                  </div>
                  <div class="selects-container-general">
                    <span>{{$t('label.applyPlan')}}</span>
                    <div class="position-relative d-flex justify-content-center">
                      <CSwitch
                        color="primary"
                        :checked="false"
                        size="lg"
                        :disabled="puerto == ''"
                        @update:checked="aplicaPlano"
                      />
                    </div>
                  </div>
                </div>
              </CCollapse>
            </li>
          </ul>
        </div>
      </nav>

      <!-- Page Content  -->
      <div id="content">
        <!-- Navbar -->
        <CNavbar
          expandable="lg"
          light class="menu-view-cargo no-margin"
          color="secondary"
        >
           <div class="sidebar-controls">
            <CButton
              color="secondary"
              shape="square"
              class="mr-2"
              size="sm"
              @click.stop="toggleSidebar"
            >
              <CIcon name="cil-menu" />
              <span class="sr-only">Toggle Menu</span>
            </CButton>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              :disabled="isMinimumZoom"
              @click.stop="minusZoom"
            >
              <CIcon name="cil-zoom-out" />
            </CButton>
            <a
              href="#"
              class="zoom-number"
              @click.prevent="resetZoom"
            >
              {{ `${zoom}%` }}
            </a>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              @click.stop="plusZoom"
            >
              <CIcon name="cil-zoom-in" />
            </CButton>
          </div>
          <CToggler in-navbar @click="collapsed = !collapsed" />
          <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
              <div class="mr-3 ml-2 selects-container">
                <span>{{ `${getTpEdiName}:` }}</span>
                <CInput
                  size="sm"
                  :disabled="true"
                  :value.sync="getEdiName"
                  class="no-margin-select ml-2"
                  v-if="modulo == 'stowage'"
                >
                </CInput>
              </div>
              <div class="position-relative ml-2 selects-container">
                <span>{{$t('label.viewBy')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2"
                  :options="viewTypes"
                  :value.sync="viewType"
                />
              </div>
               <div class="position-relative ml-2 selects-container">
                <span>BAY</span>
                <CSelect
                  size="sm"
                  placeholder="Seleccione un Filtro"
                  class="no-margin-select ml-2"
                  :options="baysOptions"
                  :disabled="isBayListEmpty"
                  :value.sync="baySelected"
                />
              </div>
            </CNavbarNav>
          </CCollapse>
          <div class="btn-pagination">
            <span>{{$t('label.page')}} {{ currentPage }} {{$t('label.of')}} {{ numberOfPages}}</span>
            <CButtonGroup class="ml-2">
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.first')} ${$t('label.page')}`,
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="firstPage"
              >
                <CIcon name="cil-chevron-double-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.previous'),
                  placement: 'top-end',
                }"
                :disabled="leftBtnsDisabled"
                @click.stop="previousPage"
              >
                <CIcon name="cil-arrow-thick-to-left" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.next'),
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="nextPage"
              >
                <CIcon name="cil-arrow-thick-to-right" />
              </CButton>
              <CButton
                color="primary"
                shape="square"
                size="sm"
                v-c-tooltip="{
                  content: `${$t('label.last')} ${$t('label.page')}`,
                  placement: 'top-end',
                }"
                :disabled="rightBtnsDisabled"
                @click.stop="lastPage"
              >
                <CIcon name="cil-chevron-double-right" />
              </CButton>
            </CButtonGroup>
          </div>
        </CNavbar>
        <!-- Contenido Interno -->
        <div class="container-fluid">
          <CRow class="mt-5 mb-3">
            <CCol sm="12">
              <div 
                class="zoom-container"
                :class="{ 
                  'grab-bing': !isOgZoom ? true:null,
                  'zoom-center': isLessThanOgZoom ? true:null,
                }"
                v-dragscroll="!isOgZoom"
              >
                <div
                  class="bay-view-container"
                  v-if="!isBayListEmpty"
                  :style="{ width: `${zoom}%` }"
                >
                  <div class="bay-view" v-for="bay in baysListFormated" :key="bay.BayCode">
                    <bay-container
                      bayView
                      showStatusView
                      :maxSize="zoom"
                      :bayInfo="bay"
                      :numberSize="30"
                      :matching="getFilterMatching"
                      :positionsToDeck="getPositionsToDeck(bay)"
                      :positionsUnderDeck="getPositionsUnderDeck(bay)"
                      :colSize="generalCol"
                      :filterPort="filterByPort"
                      :viewType="viewType"
                      :header="baysListFormated.length != 1"
                    />
                  </div>
                </div>
                <div class="bay-info">
                  <div class="position-relative">
                    <ul>
                      <li>TOTAL TEUS: {{ bayInfo.teus }}</li>
                      <li>TOTAL CONT 20 FT: {{ bayInfo.contTwenty }}</li>
                      <li>TOTAL CONT 40 FT: {{ bayInfo.contForty }}</li>
                      <li>TOTAL CONT 45 FT: {{ bayInfo.contFortyFive }}</li>
                      <li>BAY {{ bayInfo.name }}</li>
                    </ul>
                    <CElementCover
                      :opacity="1"
                      v-show="isLoadingBayInfo"
                    >
                      <CSpinner />
                    </CElementCover>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayContainer from "@/pages/planificacion-estiba/archivos/bays/bay-container";
import Pagination from '@/_mixins/PaginateMixin';
import BayMixin from "@/_mixins/bays";
import Zoom from '@/_mixins/ZoomMixin';
import { dragscroll } from 'vue-dragscroll';
import { DateFormater } from '@/_helpers/funciones';
import MatchingModal from "./matching-modal";

import ldb from 'localdata';

// Data
function data() {
  return {
    isLoading: false,
    isLoadingBayInfo: false,
    items: [],
    showLayout: false,
    collapsed: false,
    IntervalId: null,
    generalCol: 0,
    baySelected: '',
    bayInfo: {
      teus: 0,
      contTwenty: 0,
      contForty: 0,
      contFortyFive: 0,
      name: '',
    },
    //Usadas para los filtros de preferencias.
    viewId: '',
    viewTypes: [
      { label: 'DECK TO', value: '1' },
      { label: 'UNDER DECK', value: '2' },
      { label: 'ALL', value: '3' },
    ],
    viewType: '3',
    filtros: [],
    filtro: '',
    isLoadingFiltros: false,
    puertos: [],
    puerto: '',
    isLoadingPuertos: false,
    //Pagination
    numberPerPage: 1,
    //Filtros
    multiple: false,
    normales: false,
    // matching
    matchingModal: false,
    matching: null,
    // Visita
    visitInfo : null,
    visitItinerary: null,
    //Aplica Plano
    apply: false,
  };
}
// methods:
function getBaysCargoInfo(act=false) {
  if(this.modulo == 'stowage')
    this.$store.state.planificacionestiba.apiStateForm = 1;
  if(this.modulo == 'visit')
    this.$store.state.visitas.apiStateForm = 1;

  clearInterval(this.IntervalId);
  let ruta = this.modulo == 'stowage'
    ? 'StowagePlanningVesselBayCargo-list':'VisitVesselBayCargo-list';

  let parametros = this.modulo == 'stowage'
    ? {
      StowagePlanningId: this.planificacionId,
      EdiFileId: this.ediFileId,
    } 
    : {
      VisitId: this.visitId,
      EdiFileId: this.visitInfo.EdiFileId,
    };

  this.$http.ejecutar("GET", ruta, parametros)
    .then((response) => {
      this.items = response.data.data;
      this.generalCol = this.generalResponsiveCol(this.items);
      if(!this.apply && !act){
        this.baySelected = this.items?.[0]?.VesselBayId;
        this.getBayInfo(this.baySelected);
      }
      this.showLayout = true;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if(this.modulo == 'stowage')
        this.$store.state.planificacionestiba.apiStateForm = 0;
      if(this.modulo == 'visit'){
        this.$store.state.visitas.apiStateForm = 0;
        this.interval();
      }
    });
}

function interval() {
  this.IntervalId = setInterval(() => {
    if (this.$store.state.visitas.apiStateForm == 0) {
      this.refreshBaysList();
    }
  }, 600000)
}

function getFiltros() {
  if(this.modulo == 'stowage') {
    this.filtros = [
      {
        label: 'DELIVERY PORT',
        value: 'StowagePlanningDeliveryPort-list',
        EditPreferenceElement: 'DeliveryPortId',
      },
      {
        label: 'DISCHARGE PORT',
        value: 'StowagePlanningDischargePort-list',
        EditPreferenceElement: 'DischargePortId',
      },
      {
        label: 'LOAD PORT',
        value: 'StowagePlanningLoadPort-list',
        EditPreferenceElement: 'LoadPortId',
      },
    ];
  }
  if(this.modulo == 'visit') {
    this.filtros = [
      {
        label: 'DELIVERY PORT',
        value: 'VisitPortDelivery-list',
        EditPreferenceElement: 'DeliveryPortId',
      },
      {
        label: 'DISCHARGE PORT',
        value: 'VisitPortDischarge-list',
        EditPreferenceElement: 'DischargePortId',
      },
      {
        label: 'LOAD PORT',
        value: 'VisitPortLoad-list',
        EditPreferenceElement: 'LoadPortId',
      },
    ];
  }
}
function getPuertos(ServiceUrl = '') {
  if(ServiceUrl == null || ServiceUrl == '')
    return;

  let parametros = this.modulo == 'stowage'
    ? {
      StowagePlanningId: this.planificacionId,
      StowagePlanningEdiId: this.ediFileId,
    }
    : {
      VisitId: this.visitId,
      VisitEdiFileId: this.visitInfo.EdiFileId,
    } 
  
  this.isLoadingPuertos = true;
  this.$http
    .ejecutar("GET", ServiceUrl, parametros)
    .then((response) => {
      this.puertos = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingPuertos = false;
    });
}
function resetData() {
  this.generalCol =  0;
  this.baySelected = '';
  this.bayInfo.teus = 0;
  this.bayInfo.contTwenty = 0;
  this.bayInfo.contForty = 0;
  this.bayInfo.contFortyFive = 0;
  this.bayInfo.name = 0;
  //Usadas para los filtros de preferencias.
  this.viewType = '3';
  this.filtros = [];
  this.filtro = '';
  this.puertos = [];
  this.puerto = '';
  // Filtros multiples
  this.multiple = false;
  this.normales = false;
  this.matching = null;
  // Visita
  this.visitaInfo = null;
  this.visitItinerary = null;
  this.apply = false;
}
function toggleSidebar() {
  document.querySelector("#detailSidebar").classList.toggle('active');
}
function getBayInfo(bayId) {
  let found = this.filterItems.find((item) => item?.VesselBayId == bayId);

  if(!found)
    return;
  
  this.bayInfo.teus = found.Teus;
  this.bayInfo.name = found.BayCode;

  let counts = this.getBayCountsBySize(found);
  this.bayInfo.contTwenty = counts.twenty;
  this.bayInfo.contForty = counts.forty;
  this.bayInfo.contFortyFive = counts.fortyFive;
}
function getBayCountsBySize(bay) {
  let contTwenty = 0;
  let contForty = 0;
  let contFortyFive = 0;

  if(!bay.PositionJson)
    return {
      twenty: contTwenty,
      forty: contForty,
      fortyFive: contFortyFive,
    }

  bay.PositionJson.forEach((pos) => {
    if(pos.CargoJson != null && pos.CargoJson.length != 0){
      switch (pos.CargoJson[0].Size) {
        case "20":
          contTwenty++;
          break;
        case "40":
          contForty++;
          break;
        case "45":
          contFortyFive++;
          break;
        default:
          break;
      }
    }
  });

  return {
    twenty: contTwenty,
    forty: contForty,
    fortyFive: contFortyFive,
  }
}

function printPdf() {
  let pageInfo = {
    baysList: this.filterItems,
    itineraryInfo: this.currentItinerary,
    generalCol: this.generalCol,
    filter: this.filterByPort,
    type: 'bay-view',
    matching: this.matching,
    modulo: this.modulo,
  };

  ldb.set('pageInfo', JSON.stringify(pageInfo));
  // localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfPage'});
  window.open(routeData.href, '_blank');
}
function toggleMultiple() {
  this.multiple = !this.multiple;
}
function toggleNormales() {
  this.normales = !this.normales;
}
function toggleMatching() {
  this.matchingModal = true;
}
function handleMatching(match) {
  this.matching = match ? {...match} : null;
  this.filtro = '';
}
function getVisitInfo() {
  this.$http
    .ejecutar("GET", "VisitEdiFile-list", {
      VisitId: this.visitId,
    })
    .then((response) => {
      if(response.data.data.length != 0) {
        this.visitInfo = {...response.data.data[0]};
        this.getFiltros();
        this.getBaysCargoInfo();
        this.getVisitItinerary();
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}
function getVisitItinerary() {
  this.$http
    .ejecutar("GET", "Visit-by-VisitId", {
      VisitId: this.visitId,
    })
    .then((response) => {
      this.visitItinerary = {...response.data.data[0]};
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    });
}

function aplicaPlano(val, e){
  this.apply = val;
  this.baySelected = this.filterItems?.[0]?.VesselBayId;
  this.getBayInfo(this.baySelected);
}
function refreshBaysList() {
  this.getBaysCargoInfo(true);
}

//Computadas:
function isFiltrosEmpty() {
  return this.filtros.length === 0;
}
function isPuertosEmpty() {
  return this.puertos.length === 0;
}
function puertosOptions() {
  return this.puertos.map((item) => Object.assign({}, item, {
    label: item.PortCode,
    value: item.PortId
  }));
}
function filterByPort() {
  let filter  = this.filtros.find((item) => item.value === this.filtro);
  let port  = this.puertos.find((item) => item.PortId === this.puerto);

  return {
    FilterType: filter ? filter.EditPreferenceElement : '',
    PortId: port ? port.PortId : '',
    PortCode: port ? port.PortCode : ''
  }
}

function isBayListEmpty() {
  return this.filterItems.length === 0;
}
function baysOptions() {
  return this.filterItems.map((item) => Object.assign({}, item, {
    label: item.BayCode,
    value: item.VesselBayId
  }));
}
function baysListFormated() {
  if(this.baySelected != '')
    return this.filterItems.filter((bay) => bay?.VesselBayId == this.baySelected);

  return this.pageList;
}
function currentItinerary() {
  if(this.modulo == 'stowage') {
    if(this.ItineraryId !== 0 || this.ItineraryId !== null){
      const Index = this.myDataItinirary.map(function(e) {
          return e.ItineraryId; 
      }).indexOf(this.ItineraryId);
      
      if(Index > -1){
        return {
          DatePlanning: this.myDataItinirary[Index].DatePlanning,
          TpVesselName: this.myDataItinirary[Index].TpVesselName,
          Voyage: this.myDataItinirary[Index].Voyage,
          Ata:  this.myDataItinirary[Index].Ata ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Ata) : 'N/A' ,
          Atd: this.myDataItinirary[Index].Atd ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Atd) : 'N/A' ,
          Eta:  this.myDataItinirary[Index].Eta ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Eta) : 'N/A' ,
          Etd: this.myDataItinirary[Index].Etd ? DateFormater.formatDateTimeWithSlash(this.myDataItinirary[Index].Etd) : 'N/A' ,
          VesselId: this.myDataItinirary[Index].VesselId,
          VesselName: this.myDataItinirary[Index].VesselName,
          VesselFlagRoute: this.myDataItinirary[Index].VesselFlagRoute,
          VesselRoute: `${this.myDataItinirary[Index].VesselRoute}`,
          ServiceCode: this.myDataItinirary[Index].ServiceCode,
          Imo: this.myDataItinirary[Index].Imo,
          Mmsi: this.myDataItinirary[Index].Mmsi,
          Proa: this.myDataItinirary[Index].Proa,
          Popa: this.myDataItinirary[Index].Popa,
          Teus: this.myDataItinirary[Index].Teus,
          VesselTeus: this.myDataItinirary[Index].VesselTeus,
          CountryName: this.myDataItinirary[Index].CountryName,
        }
      } else return null;
    }
  }
  if(this.modulo == 'visit') {
     if(this.VisitId !== '' && this.visitItinerary){
        return {
          Voyage: this.visitItinerary.VoyageArrival,
          LabelArrival: this.visitItinerary.LabelArrival,
          Arrival:  this.visitItinerary.Arrival ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Arrival) : 'N/A' ,
          LabelDeparture: this.visitItinerary.LabelDeparture,
          Departure:  this.visitItinerary.Departure ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Departure) : 'N/A' ,
          Ata:  this.visitItinerary.Ata ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Ata) : 'N/A' ,
          Atd: this.visitItinerary.Atd ? DateFormater.formatDateTimeWithSlash(this.visitItinerary.Atd) : 'N/A' ,
          VesselId: this.visitItinerary.VesselId,
          VesselName: this.visitItinerary.VesselName,
          Imo: this.visitItinerary.Imo,
        }
     } else return null;
  }
  return null;
}
function getTpEdiName() {
  if(this.modulo == 'stowage') {
    if(!this.ediInfo) return '';
  
    if(this.ediInfo.TpEdiId == 'AE4FB2C8-8471-43C8-9DE0-6394173F0330') {
      return this.ediInfo.TpEdiTransacName;
    } else {
      return this.ediInfo.TpEdiName;
    }
  } 
  if(this.modulo == 'visit') {
    if(!this.visitInfo) return '';

    return this.visitInfo.TpEdiName;
  }

  return '';
}
function getEdiName() {
  if(this.modulo == 'stowage') {
    if(!this.ediInfo) return '';
  
    return this.ediInfo.EdiFileName;
  } 
  if(this.modulo == 'visit') {
    if(!this.visitInfo) return '';
  
    return this.visitInfo.EdiFileName;
  } 

  return '';
}
function getFilterMatching() {
  return !this.matching
    ? []
    : [...this.matching.row, ...this.matching.col];
}

function filterItems(){
  if(!this.apply)
    return this.items;
  else{
    let filtro = this.filterByPort;

    return this.items.filter((item) => {
      if(!item.PositionJson) return false;
      for (let index = 0; index < item.PositionJson.length; index++) {
        if(item.PositionJson[index].CargoJson && item.PositionJson[index].CargoJson[0][filtro.FilterType] == filtro.PortId)
          return true
      }

      return false;
    });
  }
}

//Overwrite
//Computeds
function numberOfPages() {
  return Math.ceil(this.filterItems.length / this.numberPerPage);
}
function pageList() {
  var begin = ((this.currentPage - 1) * this.numberPerPage);
  var end = begin + this.numberPerPage;

  return this.filterItems.slice(begin, end);
}
function leftBtnsDisabled() {
  return this.currentPage == 1 || this.filterItems.length == 0;
}
function rightBtnsDisabled() {
  return this.currentPage == this.numberOfPages || this.filterItems.length == 0;
}

export default {
  name: "bay-view",
  components: {
    BayContainer,
    MatchingModal,
  },
  mixins: [BayMixin, Pagination, Zoom],
  props: {
    modulo: {
      type: String,
      default: 'stowage'
    }
  },
  data,
  beforeDestroy(){
    clearInterval(this.IntervalId);
  },
  directives: {
    dragscroll
  },
  methods: {
    getBaysCargoInfo,
    interval,
    getFiltros,
    getPuertos,
    resetData,
    toggleSidebar,
    getBayInfo,
    getBayCountsBySize,
    printPdf,
    toggleNormales,
    toggleMultiple,
    toggleMatching,
    handleMatching,
    getVisitInfo,
    getVisitItinerary,
    aplicaPlano,
    refreshBaysList,
  },
  computed: {
    isFiltrosEmpty,
    isPuertosEmpty,
    puertosOptions,
    filterByPort,
    isBayListEmpty,
    baysOptions,
    baysListFormated,
    currentItinerary,
    getEdiName,
    getTpEdiName,
    getFilterMatching,
    filterItems,
    numberOfPages,
    pageList,
    leftBtnsDisabled,
    rightBtnsDisabled,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      dropItem: (state) => state.planificacionestiba.dropItem,
      collapsePlanificacion: (state) => state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
      ediInfo: (state) => state.planificacionestiba.EdiFileInfo,
      ItineraryId: state => state.planificacionestiba.ItineraryId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
      visitId: state => state.visitas.VisitId,
      dropItemElectronic: state => state.visitas.dropItemElectronic,
    }),
  },
  watch: {
    dropItem: function (newItem) {
      if (newItem == 14) {
        this.getFiltros();
        this.getBaysCargoInfo();
      } else {
        this.showLayout = false;
        this.resetData();
      }
    },
    items: function (newItems) {
      if (this.baySelected && newItems.length != 0) {
        this.getBayInfo(this.baySelected);
      }
    },
    dropItemElectronic: function (newItem) {
      if(this.modulo == 'visit'){
        if (newItem == 2) {
          this.getVisitInfo();
        } else {
          clearInterval(this.IntervalId);
          this.showLayout = false;
          this.resetData();
        }
      }
    },
    filtro: function(newFilter) {
      if(newFilter){
        this.getPuertos(newFilter);
      }
      this.puerto = '';
    },
    puerto: function(newPuerto) {
      if(newPuerto){
        this.matching = null;
      } else {
        this.puertos = [];
      }
    },
    baySelected: function(newId) {
      if(newId){
        this.getBayInfo(newId);
        let index = this.filterItems.findIndex((item) => item?.VesselBayId == newId);
        if(index != -1)
          this.currentPage = index+1;
      }
    },
    currentPage: function(newValue) {
      if(newValue) {
        this.baySelected = this.filterItems?.[newValue-1]?.VesselBayId;
      }
    },
    collapsePlanificacion: function(newValue) {
      if(!newValue) {
        this.showLayout = false;
        this.resetData();
      }
    },
    isHidden: function(newValue) {
      if(!newValue) {
        this.showLayout = false;
        this.resetData();
      }
    }
  },
};
</script>

<style scoped>
/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.bay-view {
  position: relative;
  margin: 10px 5px;
  width: 100%;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 773px;
  position: relative;
}
.bay {
  position: relative;
  margin: 0 0 5px 5px;
  width: 100%;
  padding: 10px 0;
  /* overflow: hidden; */
}
.bay-info {
  /* position: absolute;
  bottom: -18px;
  right: -15px; */
  width: 165px;
  height: 85px;
  margin-left: auto
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Zoom */
.zoom-container {
  width: 100%;
  max-height: 800px;
  overflow: auto;
  /* scrollbar-width: thin; */
}
.zoom-center {
  display: flex;
  justify-content: center;
}
.sidebar-controls {
  display: flex;
  
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}
.zoom-number {
  padding:5px;
  background-color: #d6d7db;
  border: 1px solid #636f83;
}
.zoom-number:hover {
  background-color: #636f83;
  color: #d8dbe0;
}
.grab-bing {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}
.grab-bing:active {
  cursor : -webkit-grabbing;
  cursor : -moz-grabbing;
  cursor : -o-grabbing;
  cursor : grabbing;
}

/* Sidebar */
.planificacion-body #detailSidebar {
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #e6e3e3 !important;
  background-image: none;
  border: 1px solid #000;
  transition: all 0.3s;
  z-index: 1;
}
.planificacion-body #content {
  min-height: 834px;
}
.sidebar-inner-space {
  /* margin-top: 2rem !important */
  margin-top: 0;
}
.planificacion-body #detailSidebar.active {
  width: 0;
	height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0; /* sólo en caso de elementos inline-block */
  overflow: hidden;
  margin-left: -200px;
}
.btn-sidebar-detail {
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}

/* Navbar */
.menu-view-cargo.bg-secondary {
  background-color: #f5f7fa !important;
  border: 1px solid #636f83;
  border-top: none;
  box-shadow: 0 6px 6px -6px #000;
}
.menu-view-cargo.no-margin {
  box-shadow: none;
  /* margin-bottom: 0; */
}
.nav-internal-item {
  color: #8994a8 !important;
}
.nav-internal-item:hover {
  color: #556279 !important;
}
.nav-internal-dropdown {
  background-color: #f5f7fa !important;
}
.no-margin-select {
  margin-bottom: 0;
}
.selects-container {
  display:flex; 
  justify-content: center;
  align-items: center;
}

/* Pagination */
.btn-pagination {
  position: absolute;
  bottom: -32px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

/* Filtros multiples */
.filter-collapse {
  width: 100%;
  cursor: pointer;
  color: #4e4c4d;
  background-color: #f8f6f7;
  border: 1px solid #abaaa8;
  border-left: none;
  border-right: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-icon {
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.selects-container-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
</style>