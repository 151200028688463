<template>
<CModalExtended
  :title="modalTitle"
  color="dark"
  size="xl"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <CRow class="options" v-if="hasContainerData">
    <CCol v-if="discharge" sm="12" class="text-right mb-2">
      <CButton
        color="add"
        @click="(toggle(false), $emit('onDischarge'))"
      >
        {{$t('label.discharge')}}
      </CButton>
    </CCol>
    <CCol sm="12" lg="3">
      <table class="container-table bigger-left-column">
       <thead>
         <tr>
           <th colspan="2">
             <div class="d-flex align-items-center"> 
                <CIcon name="cil-info"/>
                <span class="ml-2">{{$t('label.generalInfo')}}</span>
             </div>
            </th>
         </tr>
       </thead>
      <tbody>
        <tr>
          <td>{{$t('label.containerCode')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].ContainerCode) }}</td>
        </tr>
        <tr>
          <td>BAY:</td>
          <td>{{ container.CargoJson && container.CargoJson[0].Size == 40
            ? validateValue(container.BayCode)
            : validateValue(container.BayCodeNominal) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.Slot')}}:</td>
          <td>{{ container.CargoJson && container.CargoJson[0].Size == 40
            ? validateValue(container.CodPositionLabel)
            : validateValue(container.CodPosition) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.size')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].Size)+' '+validateValue(container.CargoJson[0].UnitMeasureSizeAbbrev, true) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.weight')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].WeigthReal)+' '+validateValue(container.CargoJson[0].UnitMeasureWeigthAbbrev, true) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.class')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].ClassInfo) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.status')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].TpCargoStatusName) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.loadPort')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].LoadPortCode) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.dischargePort')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].DischargePortCode) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.carrier')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].ShippingLineCode) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.commodity')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].Commodity) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.PlaceOfDelivery')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].DeliveryPortCode) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.CargoOriginPort')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].OriginalPortLoading) }}</td>
        </tr>
        <tr>
          <td>{{$t('label.TransshipmentPortCode')}}:</td>
          <td>{{ validateValue(container.CargoJson[0].TranshipmentPortCode) }}</td>
        </tr>
      </tbody>
    </table>
    </CCol>
    <CCol sm="12" lg="9">
      <CRow>
        <CCol sm="12" lg="4">
          <table class="container-table bigger-left-column">
            <thead>
              <tr>
                <th colspan="2">
                  <div class="d-flex align-items-center"> 
                    <CIcon name="cil-border-all"/>
                    <span class="ml-2">{{$t('label.IsoInfo')}}</span>
                  </div>
                 </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('label.code')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].IsoCode) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.length')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].Lenght)+' '+validateValue(container.CargoJson[0].UnitMeasureLenghtAbbrev, true) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.height')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].Height)+' '+validateValue(container.CargoJson[0].UnitMeasureHeigth, true) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.GroupType')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].TpCargoName) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.SizeFt')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].TpCargoDetailCode) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.description')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].TpCargoDetailName) }}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol sm="12" lg="4">
           <table class="container-table bigger-left-column">
            <thead>
              <tr>
                <th colspan="2">
                  <div class="d-flex align-items-center"> 
                    <CIcon name="cil-warning"/>
                    <span class="ml-2">{{$t('label.DangerousInfo')}}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('label.imdgCode')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].Class) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.UnNumber')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].UnNumber) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.IMDGPageNO')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].ImgdPageNo) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.FlashPoint')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].FlashPoint)+' '+validateValue(container.CargoJson[0].UnitMeasureFlashPointAbbrev, true) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.PackagingGroup')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].PackagingGroupCode) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.DescriptionOfGoods')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].DescriptionOfGoodsDGS) }}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol sm="12" lg="4">
           <table class="container-table info-media">
            <thead>
              <tr>
                <th>
                  <div class="d-flex align-items-center"> 
                    <CIcon name="cil-image"/>
                    <span class="ml-2">IMAGE</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <c-img-extended 
                    :src="getImgUrl" 
                    :error-options="{ text: 'N/A', width: 300, height: 150, fontSize: 40 }"
                    block
                    class="container-img"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol sm="12" lg="4">
          <table class="container-table bigger-left-column">
            <thead>
              <tr>
                <th colspan="2">
                  <div class="d-flex align-items-center"> 
                    <CIcon name="cil-chart" class="rotate-icon"/>
                    <span class="ml-2">{{$t('label.OversizeInfo')}}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('label.DimFront')}}:</td>
                <td>{{ validateTwoValues(container.CargoJson[0].DimFront, container.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.DimBack')}}:</td>
                <td>{{ validateTwoValues(container.CargoJson[0].DimBack, container.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.DimLeft')}}:</td>
                <td>{{ validateTwoValues(container.CargoJson[0].DimLeft, container.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.DimRight')}}:</td>
                <td>{{ validateTwoValues(container.CargoJson[0].DimRight, container.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.DimTop')}}:</td>
                <td>{{ validateTwoValues(container.CargoJson[0].DimTop, container.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
        <CCol sm="12" lg="4">
           <table class="container-table bigger-left-column">
            <thead>
              <tr>
                <th colspan="2">
                  <div class="d-flex align-items-center"> 
                    <CIcon name="cil-gauge"/>
                    <span class="ml-2">{{$t('label.ReeferInfo')}}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('label.measureUnit')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].UnitMeasureRfAbbrev) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.ReeferSetting')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].ReeferSetting) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.MinimumRange')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].MinimumRangeRf) }}</td>
              </tr>
              <tr>
                <td>{{$t('label.MaximumRange')}}:</td>
                <td>{{ validateValue(container.CargoJson[0].MaximumRangeRf) }}</td>
              </tr>
            </tbody>
          </table>
        </CCol>
         <CCol sm="12" lg="4">
           <table class="container-table info-media">
            <thead>
              <tr>
                <th>
                  <div class="d-flex align-items-center"> 
                    <CIcon name="cil-object-group"/>
                    <span class="ml-2">{{$t('label.icon')}}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="item">
                    <div :class="slugName(container.CargoJson[0].TpCargoName)"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </CCol>
      </CRow>
    </CCol>
  </CRow>         
  <template #footer>
    <CButton
      shape="square"
      color="wipe"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/>
      <span class="ml-1">{{ $t('button.exit') }}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import CImgExtended from '@/components/CImgExtended';
import { slugify } from '@/_helpers/funciones';

//Data
function data() {
  return {
    loaded: false,
    modalActive: this.modal,
  }
}

//Methods
function toggle(newVal) {
  this.modalActive = newVal;
}
function validateValue(value, empty = false) {
  let showValue = empty ? '' : 'N/A';

  return value || showValue;
}
function validateTwoValues(one, two) {
  if(one == null || two == null)
    return 'N/A';

  return `${one} ${two}`
}
function slugName(name) {
  if(name)
    return slugify(name);
  else
    return null;
}

//Computed
function hasContainerData() {
  return this.container != null;
}
function modalTitle() {
  let cod = this.container
    ? this.container.CargoJson
      ?this.container.CargoJson[0].Size == 40
        ? this.container.CodPositionLabel
        : this.container.CodPosition
      : 'N/A'
    : 'N/A';

  return !this.loadView ? `BAY POSITION: ${cod}`
    : `${this.$t('label.containerCode')}: ${this.container
      ? this.container.CargoJson
        ? this.container.CargoJson[0].ContainerCode : this.container.ContainerCode
      : ''
    }`;
}
function getImgUrl() {
  if(!this.container || (!this.container.CargoJson && !this.loadView))
    return `${this.$store.getters["connection/getBase"]}''}`;

  let ruta = '';
  if(this.container.CargoJson){
    ruta = this.container.CargoJson[0].GpoCargoRoute
    ? this.container.CargoJson[0].GpoCargoRoute.replace('Public/', '', null, 'i')
    : '';
  } else {
    ruta = this.container.GpoCargoRoute
      ? this.container.GpoCargoRoute.replace('Public/', '', null, 'i')
      : '';
  }

  return `${this.$store.getters["connection/getBase"]}${ruta}`;
}

export default {
  name: 'container-data-modal',
  mixins: [],
  components: { CImgExtended },
  props: {
    modal: Boolean,
    discharge: Boolean,
    container: {
      type: Object,
      default: null,
    },
    loadView: {
      type: Boolean,
      default:false,
    }
  },
  data,
  methods: {
    toggle,
    validateValue,
    validateTwoValues,
    slugName,
  },
  computed: {
    hasContainerData,
    modalTitle,
    getImgUrl
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>

<style scoped>
.options {
  min-height: 340px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.rotate-icon {
  transform: rotate(-90deg);
}

/* Container Data Table */
.container-table {
  width: 100%;
  height: 100%;
  min-height: 280px;
  border: 1px solid #9e9e9e;
  border-collapse: collapse;
}
.container-table thead{
  color: #434343;
  border-bottom: 1px solid #9e9e9e;
} 
.container-table th,
.container-table td{
  width: calc(100% / 2);
  padding: 5px 10px;
}
.container-table th {
  text-align: left;
}
.container-table tr td{  
  background-color: #f5f5f5;
  font-size: small;
}

.bigger-left-column td:nth-child(1){
  width: 60%;
}
.bigger-left-column td:nth-child(2){
  width: 40%;
}

/* Imagen e Icono */
.container-table.info-media .container-img {
  width: 60%;
  margin: 5px auto;
}
.container-table.info-media .item {
  width: 140px;
  height: 140px;
  border: 1px solid #000;
  position: relative;
  background-color: #fff;
  margin: 10px auto;
}

/* Iconos */
.flat-rack,
.ft-flat-rack {
  width: 35%;
  height: 35%;
  border: 1px solid #555;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
}
.plataforma,
.pl-plataforma {
  width: 35%;
  height: 35%;
  border: 1px solid #555;
  border-bottom: none;
  border-right: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
.tanque,
.tk-tanque {
  width: 25%;
  height: 25%;
  border: 1px solid #555;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin: 0 0 2px 2px;
}
.open-top,
.ot-open-top {
  position: absolute;
  width: 100%;
  height: 30%;
  border: 1px solid #555;
  border-top: none;
  border-right: none;
  border-left: none;
  top: 0;
}
.standard,
.st-estandar,
.estandar {
  background-image: url('/img/iconos/Standard.svg');
  position: absolute;
  width: 40%;
  height: 40%;
  top: 0;
  left: 0;
  margin-top: 2px;
  margin-left: 2px;
}
.high-cube {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 0 100% 0;
  -webkit-border-radius: 0 0 100% 0;
  border-radius: 0 0 100% 0;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 0;
  left: 0;
}
.reefer-high-cube,
.refrigerado-high-cube {
  background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
  background-position: left top, right bottom;
  background-size: 40% 40%, 40% 40%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height:100%;
}
.reefer,
.refrigerado {
  width: 40%;
  height: 40%;
  border: 1px solid #555;
  -moz-border-radius: 0 100% 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 100% 0 0 0;
  border-bottom: none;
  border-right: none;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>