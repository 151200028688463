<template>
<CRow>
  <container-data-modal :modal.sync="modal" :container="containerData" :loadView="loadView" :discharge="validateDischarge" @onDischarge="$emit('onDischarge', containerData)"/>
  <CCol sm="12" v-if="header">
    <div class="header-bay">
        <img
          :src="textToSvg(bayInfo.BayCode)"
          alt=""
          class="bay-code"
          :style="{width: `${headerSize}%`, height: `${headerSize}%`}"
        >
    </div>
  </CCol>
  <CCol sm="12">
     <CRow align-horizontal="center" v-if="showTop">
      <CCol sm="12" v-if="toDeckHasData && !loadViewComponent">
        <bay-grid
          :freePositions="freePositions"
          :visitPort="visitPort"
          :visit="visit"
          :visitType="visitType"
          :selectedPosition="visitPosition"
          :visitList="visitList"
          :listType="listType"
          :tooltip="tooltip"
          :maxCol="colSize"
          :col="colNum(false)"
          :row="rowNum(false)"
          :container="container"
          :baySlot="baySlot"
          :margin="margin"
          :maxSize="maxSize"
          :numberSize="numberSize"
          :showDashedNumbers="false"
          :showAlerts="showAlerts"
          :showErrors="showErrors"
          :alerts="alerts"
          :errors="errors"
          :bayView="bayView"
          :restow="restow"
          :restowPositions="restowPositions"
          :removeContainer="removeContainer"
          :cargoView="cargoView"
          :obligatory="obligatory"
          :showNumbers="showNumbers"
          :showStatusView="showStatusView"
          :showStatus="showStatus"
          :items="positionsToDeck"
          :crossLine="isCrossLineTo"
          :markCrossLine="markCrossLine"
          :filterPort="filterPort"
          :preferences="preferences"
          :viewElement="viewElement"
          :disabledSelect="disabledSelect"
          :matching="matching"
          @remove-load="removeLoad"
          @load-container="loadContainer"
          @selected-position="selectedPosition"
          @handle-alert="handleAlert"
        />
      </CCol>
      <CCol sm="12" v-if="toDeckHasData && loadViewComponent">
        <bay-grid-load
          :freePositions="freePositions"
          :visitPort="visitPort"
          :visit="visit"
          :visitType="visitType"
          :selectedPosition="visitPosition"
          :visitList="visitList"
          :listType="listType"
          :tooltip="tooltip"
          :maxCol="colSize"
          :col="colNum(false)"
          :row="rowNum(false)"
          :container="container"
          :baySlot="baySlot"
          :margin="margin"
          :maxSize="maxSize"
          :numberSize="numberSize"
          :showDashedNumbers="false"
          :showAlerts="showAlerts"
          :showErrors="showErrors"
          :alerts="alerts"
          :errors="errors"
          :bayView="bayView"
          :restow="restow"
          :restowPositions="restowPositions"
          :removeContainer="removeContainer"
          :cargoView="cargoView"
          :obligatory="obligatory"
          :showNumbers="showNumbers"
          :showStatusView="showStatusView"
          :showStatus="showStatus"
          :items="positionsToDeck"
          :crossLine="isCrossLineTo"
          :markCrossLine="markCrossLine"
          :filterPort="filterPort"
          :preferences="preferences"
          :viewElement="viewElement"
          :disabledSelect="disabledSelect"
          :matching="matching"
          @remove-load="removeLoad"
          @load-container="loadContainer"
          @selected-position="selectedPosition"
          @handle-alert="handleAlert"
        />
      </CCol>
    </CRow>
    <div class="hatch" :style="hatchCoverStyles"></div>
    <!-- <CRow align-horizontal="center">
      <CCol sm="12">
        <div class="hatch-container">
          <div
            class="hatch"
            :style="hatchCoverStyles"
            v-for="n in hatchNumber"
            :key="n"
          >
          </div>
        </div>
      </CCol>
    </CRow> -->
    <CRow align-horizontal="center" v-if="showBottom">
      <CCol sm="12" v-if="underDeckHasData && !loadViewComponent">
        <bay-grid
          :freePositions="freePositions"
          :visitPort="visitPort"
          :visit="visit"
          :visitType="visitType"
          :selectedPosition="visitPosition"
          :visitList="visitList"
          :listType="listType"
          :tooltip="tooltip"
          :maxCol="colSize"
          :col="colNum(true)"
          :row="rowNum(true)"
          :container="container"
          :baySlot="baySlot"
          :margin="margin"
          :maxSize="maxSize"
          :numberSize="numberSize"
          showNumbersBottom
          :showDashedNumbers="false"
          :showAlerts="showAlerts"
          :showErrors="showErrors"
          :alerts="alerts"
          :errors="errors"
          :bayView="bayView"
          :restow="restow"
          :restowPositions="restowPositions"
          :removeContainer="removeContainer"
          :cargoView="cargoView"
          :obligatory="obligatory"
          :showNumbers="showNumbers"
          :showStatusView="showStatusView"
          :showStatus="showStatus"
          :items="positionsUnderDeck"
          :crossLine="isCrossLineUnder"
          :markCrossLine="markCrossLine"
          :filterPort="filterPort"
          :preferences="preferences"
          :viewElement="viewElement"
          :disabledSelect="disabledSelect"
          :matching="matching"
          @remove-load="removeLoad"
          @load-container="loadContainer"
          @selected-position="selectedPosition"
          @handle-alert="handleAlert"
        />
      </CCol>
      <CCol sm="12" v-if="underDeckHasData && loadViewComponent">
        <bay-grid-load
          :freePositions="freePositions"
          :visitPort="visitPort"
          :visit="visit"
          :visitType="visitType"
          :selectedPosition="visitPosition"
          :visitList="visitList"
          :listType="listType"
          :tooltip="tooltip"
          :maxCol="colSize"
          :col="colNum(true)"
          :row="rowNum(true)"
          :container="container"
          :baySlot="baySlot"
          :margin="margin"
          :maxSize="maxSize"
          :numberSize="numberSize"
          showNumbersBottom
          :showDashedNumbers="false"
          :showAlerts="showAlerts"
          :showErrors="showErrors"
          :alerts="alerts"
          :errors="errors"
          :bayView="bayView"
          :restow="restow"
          :restowPositions="restowPositions"
          :removeContainer="removeContainer"
          :cargoView="cargoView"
          :obligatory="obligatory"
          :showNumbers="showNumbers"
          :showStatusView="showStatusView"
          :showStatus="showStatus"
          :items="positionsUnderDeck"
          :crossLine="isCrossLineUnder"
          :markCrossLine="markCrossLine"
          :filterPort="filterPort"
          :preferences="preferences"
          :viewElement="viewElement"
          :disabledSelect="disabledSelect"
          :matching="matching"
          @remove-load="removeLoad"
          @load-container="loadContainer"
          @selected-position="selectedPosition"
          @handle-alert="handleAlert"
        />
      </CCol>
    </CRow>
  </CCol>
</CRow>
</template>

<script>
import { mapState } from 'vuex';
import BayGrid from "@/pages/buque/bays/bay-grid";
import BayGridLoad from "@/pages/buque/bays/bay-grid-load";
import ContainerDataModal from "./container-data-modal";
import { GenerateSvgPlaceholder } from '@/_helpers/funciones';

// Data 
function data() {
  return {
    containerData: null,
    modal: false,
    loadView: false,
  }
}

//Methods:
function colNum(flag){
  if(flag && this.bayInfo){
    return Number.parseInt(this.bayInfo.RowStarboardUnderDeck) + Number.parseInt(this.bayInfo.RowPortUnderDeck);
  } else if(this.bayInfo){
    return Number.parseInt(this.bayInfo.RowStarboardToDeck) + Number.parseInt(this.bayInfo.RowPortToDeck);
  } else
    return 6;
}
function rowNum(flag){
  let count = 0;

  if(flag && this.bayInfo){
    for (let index = Number.parseInt(this.bayInfo.TierUnderDeckFrom); index <= Number.parseInt(this.bayInfo.TierUnderDeckTo); index++) {
      if(index%2 == 0)
        count++;
    }
  } else if(this.bayInfo){
    for (let index = Number.parseInt(this.bayInfo.TierToDeckFrom); index <= Number.parseInt(this.bayInfo.TierToDeckTo); index++) {
      if(index%2 == 0)
        count++;
    }
  } else
    return 6;

  return count;
}
function responsiveCol(crossLine) {
  let colToDeck = this.colNum(false);
  let colUnderDeck = this.colNum(true);
  let colNum = colToDeck >= colUnderDeck ? colToDeck : colUnderDeck;
  ;
  return crossLine ? colNum + 1 : colNum;
}

function selectedPosition(item) {
  if(this.visit) {
    if(this.freePositions || (this.visitType == 'load' && this.listType == 0) || (this.loadViewComponent && this.visitType == 'restow' && this.listType == 0)){
      this.$emit('selected-position', item);
      return;
    } else {
      if(item.CargoJson){
        this.containerData = {...item, BayCode: this.bayInfo.BayCode, BayCodeNominal: this.bayInfo.BayCodeNominal};
        this.loadView = true;
        this.modal = true;
      }
      return;
    }
  }
  if(item.CargoJson && !this.cargoView){
    this.containerData = {...item, BayCode: this.bayInfo.BayCode, BayCodeNominal: this.bayInfo.BayCodeNominal};
    this.modal = true;
  }

  if(item.CargoJson && this.cargoView){
    this.containerData = {...item, BayCode: this.bayInfo.BayCode, BayCodeNominal: this.bayInfo.BayCodeNominal};
    this.loadView = this.cargoView;
    this.modal = true;
  }
}

function removeLoad(position) {
  this.$emit('remove-load', position);
}
function loadContainer(toUpdate) {
  this.$emit('load-container', toUpdate);
}
function handleAlert(info) {
  this.$emit('handle-alert', {
    position: info.position,
    type: info.type,
  });
}

function textToSvg(text) {
  return GenerateSvgPlaceholder({
    dy: 6,
    width: 120,
    height: 18,
    fontSize: 21,
      bgColor: 'transparent',
    fontFamily: 'Roboto',
    fontWeight: 'bolder',
    textColor: '#3c4b64',
    text: `${text}`
  });
}

//Computeds:
function isCrossLineUnder() {
  if(this.bayInfo){
    return this.bayInfo.FgCrossLineUnderDeck || this.bayInfo.FgCrossLineUnderDeck == 1
    ? true : false;
  } else
    return false;
}
function isCrossLineTo() {
  if(this.bayInfo) {
    return this.bayInfo.FgCrossLineToDeck || this.bayInfo.FgCrossLineToDeck == 1
    ? true : false;
  } else
    return false;
}
function toDeckHasData() {
  return this.positionsToDeck.length != 0;
}
function underDeckHasData() {
  return this.positionsUnderDeck.length != 0;
}

function showTop() {
  return this.viewType === '1' || this.viewType === '3';
}
function showBottom() {
  return this.viewType === '2' || this.viewType === '3';
}
function hatchCoverStyles() {
  let col = this.currentCol;

  return {
    height: this.bayView || this.baySlot != '' ? '10px':'5px',
    width: this.baySlot != '' && !this.twoBays ? '40%' : '70%',
    // width: `calc( calc(100% / ${col}) - 5px)`,
    // maxWidth: this.maxSize > 40 ? `${this.maxSize}px` : '40px',
  }
}
function currentCol() {
  let underDeck = this.isCrossLineUnder ? this.colNum(true) + 1 : this.colNum(true),
      toDeck = this.isCrossLineTo ? this.colNum(false) + 1 : this.colNum(false),
      extras = this.showNumbers ? 2 : 0;

  return underDeck > toDeck ? underDeck + extras : toDeck + extras;
}
function hatchNumber() {
  return this.showNumbers ? this.currentCol : this.currentCol;
}
function validateDischarge() {
  return this.isVisit && (this.branch.PortId == this.containerData?.CargoJson?.[0]?.DischargePortId)
}

export default {
  name: 'bay-container',
  props: {
    obligatory: {
      type: Boolean,
      default: false
    },
    markCrossLine: {
      type: Boolean,
      default: false
    },
    showStatusView: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    disabledSelect: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    // Para Bay View
    viewType: {
      type: String,
      default: '3'
    },
    bayView: {
      type: Boolean,
      default: false
    },
    restow: {
      type: Boolean,
      default: false,
    },
    removeContainer: {
      type: Boolean,
      default: false,
    },
    // Info del Bay
    bayInfo: {
      type: Object,
      default: undefined
    },
    header: {
      type: Boolean,
      default: true
    },
    headerSize: {
      type: Number,
      default: 60
    },
    positionsToDeck: {
      type: Array,
      default: () => []
    },
    positionsUnderDeck: {
      type: Array,
      default: () => []
    },
    // Tamaños
    margin: {
      type: Number,
      default: 2.5
    },
    maxSize: {
      type: Number,
      default: 40
    },
    colSize: {
      type: Number,
      default: 0
    },
    numberSize: {
      type: Number,
      default: 70,
    },
    showNumbers: {
      type: Boolean,
      default: true
    },
    // Filtros
    filterPort: {
      type: Object,
      default: () => {
        return {
          FilterType: '',
          PortId: '',
          PortCode: ''
        }
      }
    },
    preferences: {
      type: Array,
      default: () => []
    },
    viewElement: {
      type: String,
      default: ''
    },
    matching: {
      type: Array,
      default: () => []
    },
    // Carga
    cargoView: {
      type: Boolean,
      default: false
    },
    container: {
      type: Object,
      default: null
    },
    baySlot: {
      type: String,
      default: '',
    },
    twoBays: {
      type: Boolean,
      default: false,
    },
    // Alertas y Errores
    showErrors: {
      type: Boolean,
      default: false
    },
    showAlerts: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    alerts: {
      type: Array,
      default: () => []
    },
    //Restows
    restowPositions: {
      type: Object,
      default: () => {
        return {
          arrival: '',
          departure: '',
        }
      }
    },
    // Ajuste de Plano
    visit: {
      type: Boolean,
      default: false
    },
    isVisit: Boolean,
    visitType: {
      type: String,
      default: ''
    },
    visitPosition: {
      type: Object,
      default: null
    },
    visitList: {
      type: Array,
      default: () => []
    },
    listType: {
      type: Number,
      default: 0
    },
    visitPort: {
      type: Object,
      default: () => {
        return {
          FilterType: '',
          PortId: '',
          PortCode: '',
          Color: '',
        }
      }
    },
    freePositions: {
      type: Boolean,
      default: false
    },
    loadViewComponent: {
      type: Boolean,
      default: false
    },
  },
  components:{
    BayGrid,
    BayGridLoad,
    ContainerDataModal
  },
  data,
  methods: {
    colNum,
    rowNum,
    responsiveCol,
    selectedPosition,
    removeLoad,
    loadContainer,
    handleAlert,
    textToSvg,
  },
  computed: {
    isCrossLineUnder,
    isCrossLineTo,
    toDeckHasData,
    underDeckHasData,
    showTop,
    showBottom,
    hatchCoverStyles,
    currentCol,
    hatchNumber,
    validateDischarge,
    ...mapState({
      branch: state => state.auth.branch,
    })
  },
}
</script>

<style scoped>
.hatch {
  width: 70%;
  height: 5px;
  margin: 0 auto;
  background: #9e9e9e;
  border: 1px solid #000;
}
 .header-bay {
      position: relative;
      width: 100%;
      height: 25px;
  }

  .header-bay .bay-code {
      position: absolute;
      width: 80%;
      height: 80%;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }

/* .header-bay {
  margin: 0;
} */

/* .hatch-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 0 20px;
} */
/* .hatch-container .hatch {
  height: 10px;
  background-color: #9e9e9e;
  margin: 2.5px; */
  /* border: 1px solid #000;
  border-left: none;
  border-right: none; */
/* } */


.header-size {
  font-size: 1rem;
}
</style>