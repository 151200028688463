<template>
  <CRow style="margin-top: 20px">
    <general-alert-modal
      :modal="generalAlertModal"
      visit
      :alertType="generalAlertType"
      @cerrarModal="generalAlertModal=false"
      @errorSelect="visitErrorSelect"
    />
    <CCol sm="12" class="position-relative">
      <div class="visit-alerts">
        <CButton
          color="wait"
          size="sm"
          class="my-2 my-sm-0 ml-2"
          @click="toggleGeneralAlert('warn')"
        >
          <CIcon name="alert" />
          <span class="mx-2">{{$t('label.alerts')}}</span>
        </CButton>
        <CButton
          color="wipe"
          size="sm"
          class="my-2 my-sm-0 ml-2"
          @click="toggleGeneralAlert('error')"
        >
          <CIcon name="error"/>
          <span class="mx-2">{{$t('label.errors')}}</span>
        </CButton>
      </div>
      <CTabs variant="tabs" :active-tab="tabChange" @update:activeTab="handleTab">
        <CTab :title="$t('label.discharge')" class="form-especial">
          <load-view visit="discharge" :isVisit="true" :plano="plano" @submit-action="handleAction" :tabChange = "tabChange"/>
        </CTab>
        <CTab :title="$t('label.loadPortSituation')" class="form-especial">
          <load-view visit="load" :plano="plano" @submit-action="handleAction" :tabChange = "tabChange"/>
        </CTab>
        <CTab title="RESTOW" class="form-especial">
          <load-view visit="restow" :plano="plano" @submit-action="handleAction" :tabChange = "tabChange"/>
        </CTab>
        <CTab :title="$t('label.canceled')" class="form-especial">
          <cancel-list :plano="plano" @submit-action="handleAction"/>
        </CTab>
        <CTab :title="$t('label.reverses')" class="form-especial">
          <reverse-list :plano="plano"/>
        </CTab>
        <!--
          <CTab :title="$t('label.listsManual')" class="form-especial">
          <manual-lists :value="tabChange" @child-refresh="transferir=false" />
          </CTab>
          <CTab :title="$t('label.historic')" class="form-especial" disabled>

          </CTab>
        -->
        
      </CTabs>
    </CCol>
  </CRow>
</template>

<script>
import ENUM from '@/_store/enum';
import { mapState } from "vuex";
import manualLists from './manual-lists';
import LoadView from '@/pages/planificacion-estiba/archivos/load/load-view';
import ReverseList from './reverse-list';
import GeneralAlertModal from "@/pages/planificacion-estiba/archivos/detalles/alerta-modal";
import CancelList from './cancel-list';

//data
function data() {
  return {
    tabChange: 0,
    plano: {
      bayList: [],
      DischageListJson: [],
      LoadListJson: [],
      RestowListJson: [],
      CancelListJson: [],
      ReverseListJson: [],
      LoadPorts: [],
      DischargePorts: [],
      Cranes: [],
    },
    generalAlertModal: false,
    generalAlertType: '',
  }
}
//methods
function handleTab(tab) {
  this.tabChange = tab;
}
function loadData(sumited = false, item = {}) {

  if(this.EdiFileId == 0){
    this.$notify({
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.NoLoadDistributionPlan'),
        type: "error"
    });
    return false;
  }

  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  
  
  let peticiones; 

  if(item.type != "restow"){
    peticiones = !sumited ? [
      this.$http.ejecutar("GET", "VisitVesselBayCargo-list", { 
        VisitId : this.VisitId , EdiFileId: this.EdiFileId 
      }),
      this.$http.ejecutar("GET", "VisitCargoAllList", { 
        VisitId : this.VisitId
      }),
      this.$http.ejecutar("GET", "VisitPortLoad-list", { 
        VisitId : this.VisitId , VisitEdiFileId: this.EdiFileId 
      }),
      this.$http.ejecutar("GET", "VisitPortDischarge-list", { 
        VisitId : this.VisitId , VisitEdiFileId: this.EdiFileId 
      }),
      this.$http.ejecutar("GET", "VisitVesselCrane-list", { 
        VisitId : this.VisitId
      }),
    ] : 
    [
      this.$http.ejecutar("GET", "VisitVesselBayCargo-list", { 
        VisitId : this.VisitId , EdiFileId: this.EdiFileId 
      })
    ]
  }
  else{
    peticiones = [
    this.$http.ejecutar("GET", "VisitVesselBayCargo-list", { 
      VisitId : this.VisitId , EdiFileId: this.EdiFileId 
    }),
    this.$http.ejecutar("GET", "VisitCargoAllList", { 
      VisitId : this.VisitId
    }),
  ]
  }
  


  Promise.all(peticiones)
    .then((responses) => {

      if(!sumited || item.type === "restow"){
        this.plano.DischageListJson = responses[1]?.data?.data[0]?.DischageListJson ?? this.plano.DischageListJson;
        this.plano.LoadListJson = responses[1].data?.data[0]?.LoadListJson ?? this.plano.LoadListJson;
        this.plano.RestowListJson = responses[1]?.data?.data[0]?.RestowListJson ?? this.plano.RestowListJson;
        this.plano.CancelListJson = responses[1]?.data?.data[0]?.CancelListJson ?? this.plano.CancelListJson;
        this.plano.ReverseListJson = responses[1]?.data?.data[0]?.ReverseListJson ?? this.plano.ReverseListJson;
      }
      
      if(!sumited){
        this.plano.LoadPorts = responses[2]?.data?.data ?? this.plano.LoadPorts;
        this.plano.DischargePorts = responses[3]?.data?.data ?? this.plano.DischargePorts;
        this.plano.Cranes = responses[4]?.data?.data;
      }

      this.plano.bayList = responses[0]?.data?.data;
      
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function refreshListsAndBays() {
  let peticiones = [
    this.$http.ejecutar("GET", "VisitVesselBayCargo-list", { 
      VisitId : this.VisitId , EdiFileId: this.EdiFileId 
    }),
    this.$http.ejecutar("GET", "VisitCargoAllList", { 
      VisitId : this.VisitId
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.plano.bayList = responses[0].data.data;
      this.plano.DischageListJson = responses[1].data.data[0].DischageListJson;
      this.plano.LoadListJson = responses[1].data.data[0].LoadListJson;
      this.plano.RestowListJson = responses[1].data.data[0].RestowListJson;
      this.plano.CancelListJson = responses[1].data.data[0].CancelListJson;
      this.plano.ReverseListJson = responses[1].data.data[0].ReverseListJson;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function refreshLists() {
  let peticiones = [
    this.$http.ejecutar("GET", "VisitCargoAllList", { 
      VisitId : this.VisitId
    }),
  ];

  Promise.all(peticiones)
    .then((responses) => {
      this.plano.DischageListJson = responses[0].data.data[0].DischageListJson;
      this.plano.LoadListJson = responses[0].data.data[0].LoadListJson;
      this.plano.RestowListJson = responses[0].data.data[0].RestowListJson;
      this.plano.CancelListJson = responses[0].data.data[0].CancelListJson;
      this.plano.ReverseListJson = responses[0].data.data[0].ReverseListJson;
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function handleAction(item){
  if(item.type == 'free-positions') {
    this.updatePositions(item.bays);
  } else if(item.type == 'refresh'){
    this.loadData();
  } else if(item.type == 'cancel') {
    this.refreshLists();
  } else{
    if(item.action == 'reverse')
      this.refreshListsAndBays();
    else {
      this.updatePositions(item.data.BayJson);
      this.loadData(item.sumited ?? false, item);
      
      if(item.type == "discharge")
        this.updateList(item.data.DischargeJson, item.type, item.action);
      if(item.type == "load")
        this.updateList(item.data.LoadJson, item.type, item.action);
      /*if(item.type == "restow")
        this.updateList(item.data.RestowJson, item.type, item.action);*/
    }
  }
}
function updatePositions(bays) {
  for (let i = 0; i < bays.length; i++) {
    let bayIndex = this.plano.bayList.findIndex(
      (item) => item.VesselBayId == bays[i].VesselBayId
    );
    if (bayIndex == -1) {
      console.error("Error en el id de response del PUT - Bay inexistente.");
      break;
    }

    if (!bays[i].PositionJson) {
      console.warn("PositionJson null, verificar servicio.");
      return;
    }

    for (let j = 0; j < bays[i].PositionJson.length; j++) {
      let pos = this.plano.bayList[bayIndex].PositionJson.findIndex(
        (item) => item.VesselBayPosId == bays[i].PositionJson[j].VesselBayPosId
      );
      if (pos == -1) {
        console.error(
          "Error en el id de response del PUT - Posicion inexistente."
        );
        return;
      }

      this.plano.bayList[bayIndex].PositionJson.splice(
        pos,
        1,
        bays[i].PositionJson[j]
      );
    }
  }
}
function updateList(list, type, action) {
  
  let keys = Object.keys(list[0]),
  currentList = type == 'discharge' 
    ? [...this.plano.DischageListJson] 
    : type == 'load' ? [...this.plano.LoadListJson] 
    : type == 'restow' ? [...this.plano.RestowListJson] : [];

  let previous = 0, next = 0;

  if(action == 'confirm'){
    previous = 1;
    next = 0;
  } else if(action == 'reverse') {
    previous = 0;
    next = 1;
  }

  // Remove from previous list
  for (let index = 0; index < keys.length; index++) {
    if(list[0][keys[index]].length != 0){
      for (let j = 0; j < list[0][keys[index]].length; j++) {
        let found = currentList[previous][keys[index]].findIndex((item) => item.VisitCargoId == list[0][keys[index]][j].VisitCargoId);
        if(found != -1)
          currentList[previous][keys[index]].splice(found, 1);
      }
    }
  }
  // Add to current list
  for (let index = 0; index < keys.length; index++) {
    if(list[0][keys[index]].length != 0){
      for (let j = 0; j < list[0][keys[index]].length; j++) {
       currentList[next][keys[index]].push(list[0][keys[index]][j]);
      }
    }
  }

  switch (type) {
    case 'discharge':
      this.plano.DischageListJson = currentList;
      break;
    case 'load':
      this.plano.LoadListJson = currentList;
      break;
    case 'restow':
      this.plano.RestowListJson = currentList;
      break;
    default:
      break;
  }
}

function visitErrorSelect(item) {
  return;
}
function toggleGeneralAlert(type) {
  this.generalAlertType = type;
  this.generalAlertModal = true;
}

//computed
function transferir() {
  return this.tabChange === 0;
}
export default {
  name: 'index-plano',
  data,
  mounted() {
    this.tabChange = 0;
    this.loadData();
  },
  methods: {
    handleTab,
    loadData,
    handleAction,
    refreshListsAndBays,
    updatePositions,
    updateList,
    visitErrorSelect,
    toggleGeneralAlert,
    refreshLists,
  },
  components: {
    manualLists,
    LoadView,
    ReverseList,
    GeneralAlertModal,
    CancelList,
  },
  computed: {
    transferir,
    ...mapState({
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
      dropItemElectronic: state => state.visitas.dropItemElectronic,
    })
  },
}
</script>

<style scoped>
.visit-alerts {
  position: absolute;
  right: 1%;
  top: 0.5%;
}
</style>