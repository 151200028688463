<template>
  <div class="mx-3">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
      class="modal-extended"
    >
      <CRow class="justify-content-center">
        <CCol lg="12" >
          <CSelect
            :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7 ml-2 '}"
            :label="$t('label.circuitName')"
            addLabelClasses="required text-right"
            v-model.trim="circuit.circuitId"
            :value.sync="circuit.circuitId"
            :is-valid="hasError($v.circuit.circuitId)" 
            :invalid-feedback="errorMessage($v.circuit.circuitId)"
            :options="circuitOptions"
            size="sm"
            @blur="$v.circuit.circuitId.$touch()"
            @change="inputsActive"
            :disabled="isAnulled"
          />
        </CCol>
        <CCol lg="12" v-if="FgCrane">
          <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
              :label="$t('label.crane')"
              :options="craneOptions"
              addLabelClasses="required text-right"
              v-model.trim="circuit.VisitCraneId"
              :value.sync="circuit.VisitCraneId"
              :is-valid="hasError($v.circuit.VisitCraneId)" 
              :invalid-feedback="errorMessage($v.circuit.VisitCraneId)"
              size="sm"
              @blur="$v.circuit.VisitCraneId.$touch()"
              @change="getHoldList"
              :disabled=" circuit.circuitId == '' || isAnulled"
          /> 
        </CCol>
        <CCol lg="12" v-if="FgHold">
          <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
              :label="$t('label.hold')"
              :options="holdOptions"
              addLabelClasses="required text-right"
              v-model.trim="circuit.vesselHoldId"
              :value.sync="circuit.vesselHoldId"
              :is-valid="hasError($v.circuit.vesselHoldId)" 
              :invalid-feedback="errorMessage($v.circuit.vesselHoldId)"
              size="sm"
              @blur="$v.circuit.vesselHoldId.$touch()"
              :disabled=" circuit.circuitId == '' || circuit.VisitCraneId == '' || isAnulled"
          />
        </CCol>
        <CCol lg="12"  v-if="FgYard">
          <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
              :label="$t('label.yard')"
              :options="yardOptions"
              addLabelClasses="required text-right"
              v-model.trim="circuit.yardId"
              :value.sync="circuit.yardId"
              :is-valid="hasError($v.circuit.yardId)" 
              :invalid-feedback="errorMessage($v.circuit.yardId)"
              size="sm"
              @blur="$v.circuit.yardId.$touch()"
              :disabled=" circuit.circuitId == '' || isAnulled || editYard"
              @change="getClientList"
          />
        </CCol>
        <CCol lg="12" v-if="FgYard">
          <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
              :label="$t('label.clientYard')"
              :options="clientOptions"
              addLabelClasses="required text-right"
              v-model.trim="circuit.YardClientTpId"
              :value.sync="circuit.YardClientTpId"
              :is-valid="hasError($v.circuit.YardClientTpId)" 
              :invalid-feedback="errorMessage($v.circuit.YardClientTpId)"
              size="sm"
              @blur="$v.circuit.YardClientTpId.$touch()"
              :disabled=" circuit.circuitId == '' || isAnulled || circuit.yardId == '' || editYard"
          />
        </CCol>
        <!--CCol lg="12" v-if="FgWeighingScale">
          <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
              :label="$t('label.truckScale')"
              :options="truckWeighingScaleOptions"
              addLabelClasses="required text-right"
              v-model.trim="circuit.truckWeghingScaleId"
              :value.sync="circuit.truckWeghingScaleId"
              :is-valid="hasError($v.circuit.truckWeghingScaleId)" 
              :invalid-feedback="errorMessage($v.circuit.truckWeghingScaleId)"
              size="sm"
              @blur="$v.circuit.truckWeghingScaleId.$touch()"
              :disabled=" circuit.circuitId == '' || isAnulled"
          />
        </CCol>
        <CCol lg="12" v-if="FgWeighingScale">
          <div class="form-group form-row">
            <label class="required text-right col-sm-11 col-lg-4 mb-0 col-form-label-sm">{{ $t('label.weight') }}</label>
            <div class="input-group col-sm-11 col-lg-7 ml-2 input-group-sm">
              <money
                  v-model.trim="$v.circuit.weigth.$model"
                  @blur="$v.circuit.weigth.$touch()"
                  v-bind="measure"
                  :class="computedInputSelect ? 'form-control is-valid' : (verifyWeigthId ? 'form-control is-invalid' : 'form-control') "
                  :disabled=" circuit.circuitId == '' || isAnulled" 
              >             
              </money>
            </div>
            <div class=" col-sm-11 col-lg-4" v-if="circuit.weigth <= 0 && verifyWeigthId"></div>
            <div class="col-sm-11 col-lg-7 text-invalid-feedback" v-if="circuit.weigth <= 0 && verifyWeigthId">
              {{$t('validation.required')}}
            </div>
          </div>
        </CCol-->
        <CCol lg="12" v-if="FgMachine">
          <CSelect
              :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
              :label="$t('label.machine')"
              :options="machineOptions"
              addLabelClasses="required text-right"
              v-model.trim="circuit.machineId"
              :value.sync="circuit.machineId"
              :is-valid="hasError($v.circuit.machineId)" 
              :invalid-feedback="errorMessage($v.circuit.machineId)"
              size="sm"
              @blur="$v.circuit.machineId.$touch()"
              :disabled=" circuit.circuitId == '' || isAnulled || circuit.yardId == ''"
          />
        </CCol>
        <CCol sm="12" lg="4" class="pr-0" v-if="FgApplyDate || FgApplyDateRange">
          <label class="col-form-label-sm required text-right col-sm-11 col-lg-12 pr-1" for="hordadesde">
            {{$t('label.from')}}
          </label>
        </CCol>
        <CCol sm="12" lg="4" v-if="FgApplyDate || FgApplyDateRange">
          <div class="form-group form-row mb-0" rol="group">
            <div class="col-sm-11 col-lg-12 input-group">
              <vue-datepicker
                valueType="format"
                format="DD/MM/YYYY"
                :open="openDateStart"
                :lang="this.$i18n.locale"
                @update:open="functionDateStart($event)"
                @clickoutside="openDateStart = false"
                @select="openDateStart = false"
                @pick="openDateStart = false"
                @close="openDateStart = false"
                :clearable="false"
                :disabled-date="validateDateRange"
                v-model.trim="$v.circuit.dateStart.$model"
                :append-to-body="false"
                class="w-100"
                :disabled=" circuit.circuitId == '' || isAnulled"
              >
                <template #input>
                  <CInput
                    addLabelClasses="required text-right"
                    addInputClasses="pr-0"
                    class="w-100 float-left"
                    v-uppercase
                    placeholder="DD/MM/YYYY"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.circuit.dateStart)"
                    v-model.trim="$v.circuit.dateStart.$model"
                    @keypress="rightDate($event)"
                    size="sm"
                    @blur="$v.circuit.dateStart.$touch()"
                    :disabled=" circuit.circuitId == '' || isAnulled"
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4" v-if="FgApplyDate || FgApplyDateRange">
          <div class="form-group form-row mb-0" rol="group">
            <div class="col-sm-11 col-lg-9 px-0 input-group">
              <vue-datepicker
                valueType="format"
                type="time"
                :format="'HH:mm'"
                :clearable="false"
                v-model.trim="$v.circuit.hourStart.$model"
                :append-to-body="false"
                class="w-100"
                :disabled=" circuit.circuitId == '' || isAnulled"
              >
                <template #input>
                  <CInput
                    addLabelClasses="required text-right"
                    class="w-100 float-left"
                    v-uppercase
                    placeholder="HH:MM"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.circuit.hourStart)"
                    v-model.trim="$v.circuit.hourStart.$model"
                    @keypress="formatHourMMHH($event)"
                    size="sm"
                    @blur="$v.circuit.hourStart.$touch()"
                    :disabled=" circuit.circuitId == '' || isAnulled"
                  >
                    <template #append-content>
                      <CIcon name="cil-clock" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4" class="pr-0" v-if="FgApplyDateRange">
          <label class="col-form-label-sm required text-right col-sm-11 col-lg-12 pr-1" for="hordadesde">
            {{$t('label.to')}}
          </label>
        </CCol>
        <CCol sm="12" lg="4" v-if="FgApplyDateRange">
          <div class="form-group form-row mb-0" rol="group">
            <div class="col-sm-11 col-lg-12 input-group">
              <vue-datepicker
                valueType="format"
                format="DD/MM/YYYY"
                :open="openDateEnd"
                :lang="this.$i18n.locale"
                @update:open="functionDateEnd($event)"
                @clickoutside="openDateEnd = false"
                @select="openDateEnd = false"
                @pick="openDateEnd = false"
                @close="openDateEnd = false"
                :clearable="false"
                :disabled-date="validateDateRange"
                v-model.trim="$v.circuit.dateEnd.$model"
                :append-to-body="false"
                class="w-100"
                :disabled=" circuit.circuitId == '' || isAnulled"
                @input="durationCircuit"
              >
                <template #input>
                  <CInput
                    addLabelClasses="required text-right"
                    addInputClasses="pr-0"
                    class="w-100 float-left"
                    v-uppercase
                    placeholder="DD/MM/YYYY"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.circuit.dateEnd)"
                    v-model.trim="$v.circuit.dateEnd.$model"
                    @keypress="rightDate($event)"
                    size="sm"
                    @blur="$v.circuit.dateEnd.$touch()"
                    :disabled=" circuit.circuitId == '' || isAnulled"
                    @input="durationCircuit"
                  >
                    <template #append-content>
                      <CIcon name="cil-calendar" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="4" v-if="FgApplyDateRange">
          <div class="form-group form-row mb-0" rol="group">
            <div class="col-sm-11 col-lg-9 px-0 input-group">
              <vue-datepicker
                valueType="format"
                type="time"
                :format="'HH:mm'"
                :clearable="false"
                v-model.trim="$v.circuit.hourEnd.$model"
                :append-to-body="false"
                class="w-100"
                :disabled=" circuit.circuitId == '' || isAnulled"
                @input="durationCircuit"
              >
                <template #input>
                  <CInput
                    addLabelClasses="required text-right"
                    class="w-100 float-left"
                    v-uppercase
                    placeholder="HH:MM"
                    :invalid-feedback="$t('label.required')"
                    :is-valid="hasError($v.circuit.hourEnd)"
                    v-model.trim="$v.circuit.hourEnd.$model"
                    @keypress="formatHourMMHH($event)"
                    size="sm"
                    @blur="$v.circuit.hourEnd.$touch()"
                    :disabled=" circuit.circuitId == '' || isAnulled"
                    @input="durationCircuit"
                  >
                    <template #append-content>
                      <CIcon name="cil-clock" />
                    </template>
                  </CInput>
                </template>
                <template #icon-calendar>
                  <div style="display: none"></div>
                </template>
              </vue-datepicker>
            </div>
          </div>
        </CCol>
        <label v-if="FgApplyDateRange" class="col-form-label col-sm-12 col-lg-4 pr-0 text-right">
            {{$t('label.duration')}}
          </label>
          <div v-if="FgApplyDateRange" class="col-sm-12 col-lg-8 text-center">
            <h3 class="label-duration" style="display: inline-block;">{{ circuit.duration }}</h3>
          </div>
        <CCol lg="12">
          <CTextarea
            :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
            :label="$t('label.observation')"
            addLabelClasses="text-right"
            v-uppercase
            v-model.trim="circuit.observation"
            :value.sync="circuit.observation"
            :is-valid="hasError($v.circuit.observation)" 
            :invalid-feedback="errorMessage($v.circuit.observation)"
            size="sm"
            @blur="$v.circuit.observation.$touch()"
            :disabled="isAnulled"
            maxlength="150"
          />
        </CCol>
        <CCol lg="12" v-if="isEdit">
          <CSelect
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-11 col-lg-4', input:'col-sm-11 col-lg-7  ml-2 '}"
            addLabelClasses="text-right required"
            v-model="circuit.status"
            :value.sync="circuit.status"
            :is-valid="statusSelectColor"
            :options="selectOptions"
            size="sm"
            :disabled="isAnulled"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton v-if="!isAnulled" outline color="add" @click="changeStatus()" >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton v-if="!isAnulled" color="wipe" @click="closeModal" >
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
        <CButton v-if="isAnulled" color="wipe" @click="closeModal" >
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import mixinGeneral from '@/_mixins/general';
import mixinServicio from '@/_mixins/servicio';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import { circuit } from '@/_validations/incidence/circuitValidation';
import { VMoney, Money } from "v-money";
import moment from 'moment';


function data() {
  return {
    
    circuit: {
      circuitId: '',
      VisitCraneId: '',
      vesselHoldId: '',
    //  truckWeghingScaleId: '',
      yardId: '',
      machineId: '',
      dateStart: 'DD/MM/YYYY',
      hourStart: 'HH:MM',
      dateEnd: 'DD/MM/YYYY',
      hourEnd: 'HH:MM',
      observation: '',
      duration: '00:00',
    //  weigth: 0,
      status: 1,
      FgActStatus: 1,
      orderCircuitId: '',
      YardClientTpId: '',
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
    },
    openDateStart: false,
    openDateEnd: false,
    showModal: false,
    loadingOverlay: false,
    isSubmit: false,
    isEdit: false,
  //  verifyWeigthId: false,
    isAnulled: false,
    editYard: true,

    FgApplyDate: true,
    FgApplyDateRange: true,
    FgCrane: true,
    FgHold: true,
    FgMachine: true,
    //FgWeighingScale: true,
    FgYard: true,

  //  isValidWeigth: 'form-control',
    YardClientTpId2: '',
    VisitId: '',
    titleModal: '',
    transactionBeginHour: '',
    transactionBeginDate: '',
    transactionDate: '',    
    transactionHour: '',
    circuitName: '',
    circuitOptions: [],
    circuitItems: [],
    craneOptions: [],
    holdOptions: [],
    yardOptions: [],
    clientOptions: [],
    //truckWeighingScaleOptions: [],
    machineOptions: [],
    supervisorList: [],

    previousDate: new Date(),
    laterDate: new Date(),
  };
}

  function closeModal() {
    this.refreshComponent();
    this.$emit('child-refresh', true);
    this.showModal = false;
    this.openDateStart = false;
    this.openDateEnd = false;
  }

  function functionDateStart(event) {
    if (event) {
      this.openDateStart = event;
    }
  }

  function functionDateEnd(event) {
    if (event) {
      this.openDateEnd = event;
    }
  }

  //lista circuitos
  async function getCircuitList() {
    this.loadingOverlay = true;
    this.circuitOptions = [];
    this.circuitItems = [];
    let _lang = this.$i18n.locale;
    
    let listado = Array;

    await this.$http.get("VisitCircuit-list", { VisitId: this.Visit, OrderId: this.order })
    .then(response => {
      listado = [...response.data.data];
      this.circuitItems = listado;

      if(this.circuit.circuitId != '')
        this.inputsActive();

      this.circuitOptions = listado.map(listado => Object.assign({}, this.circuitOptions, {
        value: listado.CircuitId,
        label: _lang=='en' ? listado.CircuitNameEn : listado.CircuitNameEs,
      }));

      this.circuitOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });

    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

 //lista gruas
  async function getCraneList() {
    this.loadingOverlay = true;
    this.craneOptions = [];   
    let listado = Array;
    
    await this.$http.get("VisitVesselCrane-list?VisitId="+this.Visit)
    .then(response => {
      listado = [...response.data.data];
      this.craneOptions = listado.map(listado => Object.assign({}, this.craneOptions, {
        value: listado.VisitCraneId,
        label: listado.CraneAlias,
      }));

      this.craneOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });
      
      if(this.circuit.VisitCraneId != '')
        this.getHoldList();

    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

 //lista bodegas
  async function getHoldList() {
    this.loadingOverlay = true;
    this.holdOptions = [];
    let listado = Array;

    await this.$http.get("VisitVesselCraneHold-list", { VisitId: this.Visit, VisitCraneId: this.circuit.VisitCraneId })
    .then(response => {
      listado = [...response.data.data];
      this.holdOptions = listado.map(listado => Object.assign({}, this.holdOptions, {
        value: listado.VesselHoldId,
        label: listado.VesselHoldName,
      }));

      this.holdOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });
      

    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

 //lista patios
  async function getYardList() {
    this.loadingOverlay = true;
    this.yardOptions = [];
    let listado = Array;

    await this.$http.get("VisitMetalScrapYard-list", { VisitId: this.Visit})
    .then(response => {
      listado = [...response.data.data];
      this.yardOptions = listado.map(listado => Object.assign({}, this.yardOptions, {
        value: listado.YardId,
        label: listado.YardName,
        BlYardDetailJson: listado.BlYardDetailJson,
      }));

      this.yardOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });

    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

 //lista basculas
 /* async function getTruckWeighingScaleList() {
    this.loadingOverlay = true;
    this.truckWeighingScaleOptions = [];
    let listado = Array;

    await this.$http.get("TruckWeighingScale-list", { CompanyBranchId: this.branch.CompanyBranchId, Filter: 'ACTIVO' })
    .then(response => {
      listado = [...response.data.data];
      this.truckWeighingScaleOptions = listado.map(listado => Object.assign({}, this.truckWeighingScaleOptions, {
        value: listado.TruckWeghingScaleId,
        label: listado.TruckWeghingScaleName,
      }));

      this.truckWeighingScaleOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });
      

    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }
*/
 //lista maquinaria
  async function getMachineList() {
    this.loadingOverlay = true;
    this.machineOptions = [];
    let listado = Array;

    await this.$http.ejecutar("GET", "MachineMetalScrap-list-by-YardId", 
      { CompanyBranchId: this.branch.CompanyBranchId, YardId: this.circuit.yardId }
    )
    .then(response => {
      listado = [...response.data.data];
      this.machineOptions = listado.map(listado => Object.assign({}, this.machineOptions, {
        value: listado.MachineId,
        label: listado.MachineName,
      }));

      this.machineOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });
      

    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.loadingOverlay = false;
    });
  }

  function refreshComponent() {
    this.circuit.circuitId = '';
    this.circuit.VisitCraneId = '';
    this.circuit.vesselHoldId = '';
  //  this.circuit.truckWeghingScaleId = '';
    this.circuit.yardId = '';
    this.circuit.machineId = '';
    this.circuit.hourStart = '';
    this.circuit.hourEnd = '';
    this.circuit.dateStart = '';
    this.circuit.dateEnd = '';
    this.circuit.observation = '';
    this.circuit.duration = '00:00';

  //  this.circuit.weigth = 0;
    this.transactionBeginDate = '';
    this.transactionBeginHour = '';
    this.transactionDate = '';
    this.transactionHour = '';
    this.circuitName = '';
    this.circuit.YardClientTpId = '';
    this.circuit.status = 1;
    this.circuit.FgActStatus = 1;
  //  this.isValidWeigth = 'form-control';
    this.circuit.orderCircuitId = '';

    this.openDateStart = false;
    this.openDateEnd = false;
  //  this.verifyWeigthId = false;
    this.isAnulled = false,

    this.FgApplyDate = true,
    this.FgApplyDateRange = true,
    this.FgCrane = true,
    this.FgHold = true,
    this.FgMachine = true,
    this.FgRequired = true,
    //this.FgWeighingScale = true,
    this.FgYard = true,

    this.$v.circuit.$reset();
  }

  async function submit(){
    try {
      this.loadingOverlay = true;
      this.isSubmit = true;
      this.$v.circuit.$touch();

      if (this.$v.circuit.$error) {
      //  this.verifyWeigthId = true;
        this.loadingOverlay = false;
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }

      let dateNow = new Date();
      let currentTime = DateFormater.formatDateTimeWithSlashToOnlyTime(DateFormater.formatTimeZoneToDateTime(dateNow));
      let currentDate = DateFormater.formatDateTimeWithSlashToOnlyDate(DateFormater.formatTimeZoneToDateTime(dateNow));

      let startTime = '';
      let startDate = '';
      if (this.orderData.TransactionDate!==undefined  && this.orderData.TransactionDate!==null){
          startTime = DateFormater.formatDateTimeWithSlashToOnlyTime(this.orderData.TransactionDate);
          startDate = DateFormater.formatDateTimeWithSlashToOnlyDate(this.orderData.TransactionDate);
      }

      let endTime = '';
      let endDate = '';
      if (this.orderData.FinishDate!==undefined  && this.orderData.FinishDate!==null){
        endTime = DateFormater.formatDateTimeWithSlashToOnlyTime(this.orderData.FinishDate);
        endDate = DateFormater.formatDateTimeWithSlashToOnlyDate(this.orderData.FinishDate);
      }

      /*******FECHA DESDE LLENAS*****/
      if( this.circuit.dateStart !== '' ){
        //SI LA FECHA DESDE ES MENOR AL INICIO DE LA ORDEN
        if( moment(this.circuit.dateStart,'DD/MM/YYYY').isBefore(moment(startDate,'DD/MM/YYYY') ) ){ 
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsFromDateOrderBeginning');
        }
        //SI LA FECHA DESDE ES MAYOR A LA DEL CIERRE DE LA ORDEN
        if( moment(this.circuit.dateStart,'DD/MM/YYYY').isAfter(moment(endDate,'DD/MM/YYYY') ) ){
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsFromDateOrderFinish');
        }
        //SI LA FECHA DESDE ES MAYOR A LA FECHA ACTUAL
        if( moment(this.circuit.dateStart,'DD/MM/YYYY').isAfter(moment(currentDate,'DD/MM/YYYY') ) ){
          this.loadingOverlay = false;
          this.isSubmit = false;
          throw this.$t('label.errorsFromCurrentDate');
        }
      }

      /*******FECHA HORAS DESDE LLENAS*****/
      if( this.circuit.dateStart !== '' && this.circuit.hourStart !== ''){
        //SI LA FECHA DESDE ES IGUAL AL INICIO DE LA ORDEN, Y LA HORA DESDE ES MENOR QUE LA DEL INICIO DE LA ORDEN
        if((moment(this.circuit.dateStart,'DD/MM/YYYY').isSame(moment(startDate,'DD/MM/YYYY'))) && (this.circuit.hourStart != '')){
          if( moment(this.circuit.hourStart,'HH:mm').isSameOrBefore(moment(startTime,'HH:mm') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsFromTimeOrderBeginning');
          }
        }
        //SI LA FECHA DESDE ES IGUAL A LA DEL CIERRE DE LA ORDEN, Y LA HORA DESDE ES MAYOR QUE LA DEL CIERRE DE LA ORDEN
        if((moment(this.circuit.dateStart,'DD/MM/YYYY').isSame(moment(endDate,'DD/MM/YYYY'))) && (this.circuit.hourStart != '')){
          if( moment(this.circuit.hourStart,'HH:mm').isAfter(moment(endTime,'HH:mm') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsFromTimeOrderFinish');
          }
        }
        //SI LA FECHA DESDE ES IGUAL A LA FECHA ACTUAL, Y LA HORA DESDE ES MAYOR QUE LA DE LA HORA ACTUAL
        if((moment(this.circuit.dateStart,'DD/MM/YYYY').isSame(moment(currentDate,'DD/MM/YYYY'))) && (this.circuit.hourStart != '')){
          if( moment(this.circuit.hourStart,'HH:mm').isAfter(moment(currentTime,'HH:mm') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsFromCurrentTime');
          }
        }
      }

      if(this.FgApplyDateRange){
        /*******FECHA HASTA LLENAS*****/
        if( this.circuit.dateEnd !== '' ){
          //SI LA FECHA HASTA ES MENOR AL INICIO DE LA ORDEN 
          if( moment(this.circuit.dateEnd,'DD/MM/YYYY').isBefore(moment(startDate,'DD/MM/YYYY') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsToDateOrderBeginning');
          }
          //SI LA FECHA HASTA ES MAYOR A LA DEL CIERRE DE LA ORDEN
          if( moment(this.circuit.dateEnd,'DD/MM/YYYY').isAfter(moment(endDate,'DD/MM/YYYY') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsToDateOrderFinish');
          }
          //SI LA FECHA HASTA ES MAYOR A LA FECHA ACTUAL
          if( moment(this.circuit.dateEnd,'DD/MM/YYYY').isAfter(moment(currentDate,'DD/MM/YYYY') ) ){
            this.loadingOverlay = false;
            this.isSubmit = false;
            throw this.$t('label.errorsToCurrentDate');
          }
        }

        /*******FECHA HORAS HASTA LLENAS*****/
        if( this.circuit.dateEnd !== '' && this.circuit.hourEnd !== ''){
          //SI LA FECHA DESDE ES IGUAL AL INICIO DE LA ORDEN, Y LA HORA DESDE ES MENOR QUE LA DEL INICIO DE LA ORDEN
          if((moment(this.circuit.dateEnd,'DD/MM/YYYY').isSame(moment(startDate,'DD/MM/YYYY'))) && (this.circuit.hourEnd != '')){
            if( moment(this.circuit.hourEnd,'HH:mm').isSameOrBefore(moment(startTime,'HH:mm') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsToTimeOrderBeginning');
            }
          }
          //SI LA FECHA DESDE ES IGUAL A LA DEL CIERRE DE LA ORDEN, Y LA HORA DESDE ES MAYOR QUE LA DEL CIERRE DE LA ORDEN
          if((moment(this.circuit.dateEnd,'DD/MM/YYYY').isSame(moment(endDate,'DD/MM/YYYY'))) && (this.circuit.hourEnd != '')){
            if( moment(this.circuit.hourEnd,'HH:mm').isAfter(moment(endTime,'HH:mm') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsToTimeOrderFinish');
            }
          }
          //SI LA FECHA DESDE ES IGUAL A LA FECHA ACTUAL, Y LA HORA DESDE ES MAYOR QUE LA DE LA HORA ACTUAL
          if((moment(this.circuit.dateEnd,'DD/MM/YYYY').isSame(moment(currentDate,'DD/MM/YYYY'))) && (this.circuit.hourEnd != '')){
            if( moment(this.circuit.hourEnd,'HH:mm').isAfter(moment(currentTime,'HH:mm') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsToCurrentTime');
            }
          }
        }

        //FECHA DESDE IGUAL A FECHA HASTA
        if(this.circuit.dateStart !== '' && this.circuit.hourStart !== '' && this.circuit.dateEnd !== '' && this.circuit.hourEnd !== ''){
          //SI LA FECHA DESDE ES IGUAL A LA FECHA HASTA, Y LA HORA DESDE S MAYOR QUE LA HORA HASTA
          if( moment(this.circuit.dateStart,'DD/MM/YYYY').isSame(moment(this.circuit.dateEnd,'DD/MM/YYYY'))){
            if( moment(this.circuit.hourStart,'HH:mm').isSameOrAfter(moment(this.circuit.hourEnd,'HH:mm')) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.errorsFromTimeToTime');
            }
          }
        }

        //FECHA DESDE IGUAL A FECHA HASTA
        if ( this.circuit.dateStart !== '' && this.circuit.dateEnd !== ''){
          //SI LA FECHA DESDE ES MAYOR A LA FECHA HASTA
          if( moment(this.circuit.dateStart,'DD/MM/YYYY').isAfter(moment(this.circuit.dateEnd,'DD/MM/YYYY') ) ){
              this.loadingOverlay = false;
              this.isSubmit = false;
              throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
      }

      const OrderCircuitJson = {
        OrderId: this.order, 
        CircuitId: this.circuit.circuitId,
        MachineId: this.circuit.machineId,
        TruckWeighingScaleId: '',//this.circuit.truckWeghingScaleId,
        YardId: this.circuit.yardId,
        YardClientTpId:this.circuit.YardClientTpId,//Id del Cliente en Patio 
        VisitCraneId: this.circuit.VisitCraneId,
        VesselHoldId: this.circuit.vesselHoldId,
        Weigth: this.circuit.weigth,
        Observation: this.circuit.observation,
      };

      if(this.FgApplyDate){
        OrderCircuitJson.TransactionDate = DateFormater.formatDateTimeWithoutSlash(this.circuit.dateStart+' '+this.circuit.hourStart);
      };

      if(this.FgApplyDateRange){
        OrderCircuitJson.TransactionBegin = DateFormater.formatDateTimeWithoutSlash(this.circuit.dateStart+' '+this.circuit.hourStart);
        OrderCircuitJson.TransactionFinish = DateFormater.formatDateTimeWithoutSlash(this.circuit.dateEnd+' '+this.circuit.hourEnd);
      };

      if(this.isEdit){
        OrderCircuitJson.OrderCircuitId = this.circuit.orderCircuitId;
        OrderCircuitJson.Status = this.circuit.status;
      }; 
      let method = this.isEdit ? 'PUT':'POST';
      let ruta = this.isEdit ? 'ShipmentOrderCircuit-update':'VisitShipmentOrderCircuit-insert';

      await this.$http
        .ejecutar(method, ruta, OrderCircuitJson, { root: 'OrderCircuitJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            this.closeModal();
            this.loadingOverlay = false;
            this.isSubmit = false;
            this.$notify({
              group: 'container',
              title: '¡'+this.$t('label.success')+'!',
              text: response.data.data[0].Response,
              type: "success"
            });
          }
        })
        .catch((e) => {
        this.loadingOverlay = false;
        this.isSubmit = false;
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: e,
            type: "error"
          });
        }).then(() => {
        this.loadingOverlay = false;
        this.isSubmit = false;
      }); 
      this.loadingOverlay = false;
    } catch (e) {
      this.loadingOverlay = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
      });
    }
  } 

  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }

  function getDateRange(){
    this.loadingOverlay = true;
    let currentDate = new Date();

    if (this.orderData.TransactionDate!==undefined && this.orderData.TransactionDate!==null && this.orderData.TransactionDate!== ''){
      this.previousDate = DateFormater.formatDateRange(DateFormater.formatDateTimeWithoutSlash(this.orderData.TransactionDate));
    }

    if (this.orderData.FinishDate!==undefined && this.orderData.FinishDate!==null && this.orderData.FinishDate!==''){
      this.laterDate = DateFormater.formatDateRange(DateFormater.formatDateTimeWithoutSlash(this.orderData.FinishDate));
    } else {
      this.laterDate = currentDate;
    }

    this.loadingOverlay = false;
  }

  function statusSelectColor(){
    return this.circuit.status === 1;
  }

  function changeStatus() {
    if(this.circuit.FgActStatus !== this.circuit.status){              
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')+' '} ${this.circuitName}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }

  function inputsActive(){
    const circuitData = this.circuitItems.find( item => item.CircuitId === this.circuit.circuitId );

    if(circuitData != undefined){
      this.FgApplyDate = circuitData.FgApplyDate;
      this.FgApplyDateRange = circuitData.FgApplyDateRange;
      this.FgCrane = circuitData.FgCrane;
      this.FgHold = circuitData.FgHold;
      this.FgMachine = circuitData.FgMachine;
      //this.FgWeighingScale = circuitData.FgWeighingScale;
      this.FgYard = circuitData.FgYard;

      if(this.FgApplyDate){
        if(this.isEdit){
          this.circuit.hourStart = this.transactionHour ? this.transactionHour : this.TransactionBeginHour;
          this.circuit.dateStart = this.transactionDate ? this.transactionDate : this.TransactionBeginDate;
        }
      }else if(this.FgApplyDateRange){
        if(this.isEdit){
          this.circuit.hourStart = this.TransactionBeginHour ? this.TransactionBeginHour : this.transactionHour;
          this.circuit.dateStart = this.TransactionBeginDate ? this.TransactionBeginDate : this.transactionDate;
          this.durationCircuit();
        }
      }
      if(this.FgYard && this.circuit.yardId != ''){
        this.getMachineList();
        this.getClientList();
      }

    }else{
      this.circuit.circuitId = ''; 
    } 
  } 

  function getClientList(){
    if(this.FgYard && this.circuit.yardId != '')
      this.getMachineList();

    const ClientData = this.yardOptions.find( item => item.value == this.circuit.yardId );

    
    if( ClientData != undefined ){

      this.clientOptions = [];
      let clientsList = [];
      let listado = ClientData.BlYardDetailJson;
      
      if( listado.length > 0 ){
        clientsList = listado.map(listado => Object.assign({}, clientsList, {
          value: listado.ClientTpId,
          label: listado.ClientName,
        }));
      }
      
      let setClientsList = new Set( clientsList.map( JSON.stringify ) )
      let arrayClients = Array.from( setClientsList ).map( JSON.parse );
      this.clientOptions = arrayClients;

      this.clientOptions.unshift({
        value: "",
        label: this.$t('label.select')
      });

      if(this.circuitData.StowageImplementVehicleId !== '' && this.circuitData.StowageImplementVehicleId !== null){
        this.editYard = true;
        this.circuit.YardClientTpId = this.circuitData.YardClientTpId ? this.circuitData.YardClientTpId : '';
       }
    }
  }

  function durationCircuit(){

    if (this.circuit.dateStart !== "" && this.circuit.dateEnd !== "" && this.circuit.hourStart !== "" && this.circuit.hourEnd !== "") {
      //SI LA FECHA DESDE ES IGUAL A LA FECHA HASTA, Y LA HORA DESDE S MAYOR QUE LA HORA HASTA
      if( moment(this.circuit.dateStart,'DD/MM/YYYY').isSame(moment(this.circuit.dateEnd,'DD/MM/YYYY'))){
        if( moment(this.circuit.hourStart,'HH:mm').isSameOrAfter(moment(this.circuit.hourEnd,'HH:mm')) ){
          this.circuit.duration = '00:00';
          this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.errorsFromTimeToTime'),
            type: "error"
          });
        }else{
          this.circuit.duration = DateFormater.datesDifferenceInHours(this.circuit.dateStart, this.circuit.dateEnd, this.circuit.hourStart, this.circuit.hourEnd);
        }
      }
      //SI LA FECHA DESDE ES MAYOR A LA FECHA HASTA
      else if( moment(this.circuit.dateStart,'DD/MM/YYYY').isAfter(moment(this.circuit.dateEnd,'DD/MM/YYYY') ) ){
        this.circuit.duration = '00:00';
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.ErrorFromToPleaseCheck'),
            type: "error"
          });
      }
      else{
        this.circuit.duration = DateFormater.datesDifferenceInHours(this.circuit.dateStart, this.circuit.dateEnd, this.circuit.hourStart, this.circuit.hourEnd);
      }
    }  
  }
//computed
  function selectOptions(){
    return [
        { 
          value: true, 
          label: this.$t('label.ACTIVO')
        },
        { 
          value: false, 
          label: this.$t('label.INACTIVO')
        }
      ];
  }


/*function computedInputSelect() {
  if (this.circuit.weigth <= 0) {
    return false;
  } else {
    return true;
  }
}
*/
export default {
  name: 'modal-circuit',
  data,
  props: {
    modal: null,
    order: null,
    anulled: {
      type: Boolean,
      default: false,
    },
    orderDate: null,
    circuitData: null,
    orderData: null,
  },
  mixins: [mixinGeneral, mixinServicio],
  directives: UpperCase,

  validations(){ 
    return circuit(this.FgApplyDate, this.FgApplyDateRange, this.FgCrane, this.FgHold, this.FgMachine, /*this.FgWeighingScale,*/ this.FgYard) 
  },
  components: {
    Money,
  },
  watch: {
    modal: async function () {
      if (this.modal) {
        this.showModal = true;   
        this.refreshComponent();
        this.getDateRange(); 
        this.getCircuitList(); 
        this.getCraneList();
        this.getYardList();
        //this.getTruckWeighingScaleList();

        if (this.modal==true) {
          this.isEdit  = false;
          this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.circuit');

          let currentDate = new Date();
          currentDate = DateFormater.formatTimeZoneToDateTime(currentDate);
          this.circuit.dateStart = DateFormater.formatDateTimeWithSlashToOnlyDate(currentDate);
          this.circuit.hourStart = DateFormater.formatDateTimeWithSlashToOnlyTime(currentDate);

          this.circuit.yardId = (this.circuitData.YardId != '') ? this.circuitData.YardId: '';

          if(this.circuitData.StowageImplementVehicleId !== '' && this.circuitData.StowageImplementVehicleId !== null){
            this.editYard = true;
            this.circuit.YardClientTpId = this.circuitData.YardClientTpId ? this.circuitData.YardClientTpId : '';
            
            this.getClientList();
          }else{
            this.editYard = false;
          }
        } else {
          this.isAnulled =  this.anulled;
          this.isEdit  = true;
          this.titleModal  = this.$t('label.edit')+' '+this.$t('label.circuit')+': '+this.modal.CircuitName;
          this.circuitName = this.modal.CircuitName ? this.modal.CircuitName : '';
          this.circuit.circuitId = this.modal.CircuitId ? this.modal.CircuitId : '';
          this.circuit.VisitCraneId = this.modal.VisitCraneId ? this.modal.VisitCraneId : '';
          this.circuit.vesselHoldId = this.modal.VesselHoldId ? this.modal.VesselHoldId : '';
          this.circuit.yardId = this.modal.YardId ? this.modal.YardId : '';
         // this.circuit.truckWeghingScaleId = this.modal.TruckWeighingScaleId ? this.modal.TruckWeighingScaleId : '';
          this.circuit.machineId = this.modal.MachineId ? this.modal.MachineId : '';

          this.transactionHour = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionDate) : '';
          this.transactionDate = this.modal.TransactionDate ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionDate) : '';

          this.TransactionBeginHour = this.modal.TransactionBegin ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionBegin) : '';
          this.TransactionBeginDate = this.modal.TransactionBegin ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionBegin) : '';

          this.circuit.hourEnd = this.modal.TransactionFinish ? DateFormater.formatDateTimeWithSlashToOnlyTime(this.modal.TransactionFinish) : '';
          this.circuit.dateEnd = this.modal.TransactionFinish ? DateFormater.formatDateTimeWithSlashToOnlyDate(this.modal.TransactionFinish) : '';

          this.circuit.observation = this.modal.Observation ? this.modal.Observation : '';
        //  this.circuit.weigth = this.modal.Weigth ? this.modal.Weigth : 0;
          this.circuit.status = this.modal.FgActOrderCircuit ? 1 : 0;
          this.circuit.FgActStatus = this.modal.FgActOrderCircuit ? 1 : 0;
          this.circuit.orderCircuitId = this.modal.OrderCircuitId ? this.modal.OrderCircuitId : '';
        //  this.isValidWeigth = this.$v.circuit.weigth.$error ? 'form-control is-invalid' : 'form-control is-valid';
        this.circuit.YardClientTpId = this.modal.YardClientTpId; ////////revisar

        if(this.circuitData.StowageImplementVehicleId !== '' && this.circuitData.StowageImplementVehicleId !== null){
          this.editYard = true;
          this.circuit.YardClientTpId = this.circuitData.YardClientTpId ? this.circuitData.YardClientTpId : '';
          
          this.getClientList();
        }else{
          this.editYard = false;
        }

          this.$v.$touch();
        }
        
        this.$emit('closeModal');
      }
    },
  },
  methods: {
    closeModal,
    functionDateStart,
    functionDateEnd,
    getCircuitList,
    getCraneList,
    getHoldList,
    getYardList,
    //getTruckWeighingScaleList,
    getMachineList,
    refreshComponent,
    submit,
    validateDateRange,
    getDateRange,
    statusSelectColor,
    changeStatus,
    inputsActive,
    getClientList,
    durationCircuit,
  },
  computed: {
    selectOptions,
  //  computedInputSelect,
    ...mapState({
      Visit: state => state.visitas.VisitId,
      ItineraryId: state => state.visitas.ItineraryId,
      itinerarySelected: state => state.visitas.itinerarySelected,
      user: state => state.auth.user,    
      branch: (state) => state.auth.branch,
    }),
  },
};
</script>
<style lang="scss">
</style>
